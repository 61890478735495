import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid2 as Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import ServiceAssistantsContext from '../../../../context/serviceassistant/ServiceAssistantsContext'
import ServiceAssistantApi from '../../../../api/serviceassistant/ServiceAssistantApi'
import useAuth from '../../../../context/auth/useAuth'
import useServiceId from '../../../../hooks/service/useServiceId'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import ServiceAssistantForm from '../ServiceAssistantForm/ServiceAssistantForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { ServiceAssistant } from '../../../../types/ServiceAssistant'

// apis
const serviceAssistantApi = new ServiceAssistantApi()

// ========================|| SERVICE ASSISTANT - EDIT DIALOG ||======================== //

export interface FormValues {
  customPrice: boolean,
  price: string | null
  currency: string | null
  disabledWeekdays: number[]
  availableWeekdays: number[]
}

interface Props extends DialogProps {
  serviceAssistant: ServiceAssistant
  onClose: () => void
}

export default function ServiceAssistantEditDialog({ serviceAssistant, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setServiceAssistants } = useContext(ServiceAssistantsContext)
  const serviceId = useServiceId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    defaultValues: {
      customPrice: serviceAssistant.price !== null,
      price: serviceAssistant.price !== null ? serviceAssistant.price.toString() : null,
      currency: serviceAssistant.currency,
      disabledWeekdays: serviceAssistant.disabledWeekdays,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (updated: ServiceAssistant) => {
    setServiceAssistants(serviceAssistants => serviceAssistants.map(serviceAssistant => (serviceAssistant.id === updated.id ? updated : serviceAssistant)))

    methods.reset({
      customPrice: updated.price !== null,
      price: updated.price?.toString() || null,
      currency: updated.currency,
      disabledWeekdays: updated.disabledWeekdays,
    })

    dispatch(
      setSnackbar({
        message: t('Service updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating service'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    methods.reset()
    setLoading(false)
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      serviceAssistantApi.update(token, serviceId, serviceAssistant.id, {
        price: form.price !== null ? parseFloat(form.price) : null,
        currency: form.currency,
        disabledWeekdays: form.disabledWeekdays,
      })
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Customize')}</DialogTitle>
          <DialogContent>
            <ServiceAssistantForm serviceAssistant={serviceAssistant} />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid size={12} container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  size='large'
                  loading={loading}
                  disabled={!methods.formState.isDirty}
                  fullWidth
                  disableElevation
                >
                  {t('Update')}
                </LoadingButton>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
