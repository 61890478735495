// material-ui
import { Grid2 as Grid, Typography } from '@mui/material'

// project imports
import BranchEditButton from '../BranchEditButton/BranchEditButton'
import BranchDeleteButton from '../BranchDeleteButton/BranchDeleteButton'
import { Branch } from '../../../../types/Branch'

// ========================|| BRANCH - LIST ITEM ||======================== //

interface Props {
  branch: Branch
}

export default function BranchListItem({ branch }: Props) {
  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Typography variant='subtitle1'>{branch.name}</Typography>
        <Typography variant='caption'>{branch.address.description}</Typography>
      </Grid>
      <Grid size={12} container spacing={1}>
        <Grid size={6}>
          <BranchEditButton branch={branch} />
        </Grid>
        <Grid size={6}>
          <BranchDeleteButton branch={branch} />
        </Grid>
      </Grid>
    </Grid>
  )
}
