import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'

// third-party
import { useNavigate } from 'react-router-dom'
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  onIdTokenChanged,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth'

// project imports
import AuthContext from './AuthContext'
import { FirebaseError } from '../../types/ApiError'
import { auth as firebase } from '../../firebase'

// ========================|| PROVIDER - AUTH ||======================== //

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const navigate = useNavigate()
  const [auth, setAuth] = useState<User>()
  const [error, setError] = useState<FirebaseError>()
  const [loading, setLoading] = useState(false)
  const [loadingInitial, setLoadingInitial] = useState(true)

  /**
   * Update user when auth state changes.
   */
  useEffect(() => {
    onIdTokenChanged(firebase, user => {
      if (user) setAuth(user)
      else setAuth(undefined)
      setLoadingInitial(false)
    })
  }, [])

  /**
   * Signs in a new user using Google as a social provider.
   */
  function signInWithGoogle() {
    const provider = new GoogleAuthProvider()
    return signInWithPopup(firebase, provider).catch(error => setError(error))
  }

  /**
   * Signs in a new user using Facebook as a social provider.
   */
  function signInWithFacebook() {
    const provider = new FacebookAuthProvider()
    signInWithPopup(firebase, provider).catch(error => setError(error))
  }


  /**
   * Send a sign-in link to the provided email.
   */
  function sendSignInLink(email: string, url: string) {
    setLoading(true)

    return sendSignInLinkToEmail(firebase, email, { url: url, handleCodeInApp: true })
      .then(() => window.localStorage.setItem('emailForSignIn', email))
      .catch(error => setError(error))
      .finally(() => setLoading(false))
  }

  function signInWithLink() {
    if (isSignInWithEmailLink(firebase, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn')

      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }

      signInWithEmailLink(firebase, email as string, window.location.href)
        .then(() => window.localStorage.removeItem('emailForSignIn'))
        .catch(error => setError(error))
    }
  }

  /**
   * Call the logout endpoint and then remove the user from the state.
   */
  function logout() {
    signOut(firebase).then(handleLogout)
  }

  /**
   * Handles logout action. It sets the user and token as undefined.
   */
  function handleLogout() {
    setAuth(undefined)
    navigate('/signin')
  }

  const value = useMemo(
    () => ({
      auth,
      error,
      loading,
      signInWithGoogle,
      signInWithFacebook,
      signInWithLink,
      sendSignInLink,
      logout,
    }),
    [auth, loading, error],
  )

  return <AuthContext.Provider value={value}>{!loadingInitial && children}</AuthContext.Provider>
}

export default function useAuth() {
  return useContext(AuthContext)
}
