import { useEffect } from 'react'

// third-party
import { useDispatch } from 'react-redux'

// project imports
import useCompanyId from '../../../hooks/company/useCompanyId'
import BookingCreateCard from './BookingCreateCard/BookingCreateCard'
import { setIsOpen } from '../../../store/menu/reducer'

// ========================|| COMPANY - BOOKING NEW ||======================== //

export default function BookingNew() {
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsOpen(`/companies/${companyId}/bookings`))
  }, [])

  return <BookingCreateCard />
}
