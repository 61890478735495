// material-ui
import { Box, Button, Card, CardContent, Grid2 as Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// ========================|| MAINTENANCE - NOT FOUND ||======================== //

export default function Error() {
  const { t } = useTranslation()

  const handleReload = () => {
    window.location.reload()
  }

  return (
    <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
      <Card variant='outlined' sx={{ border: 'none' }}>
        <CardContent sx={{ p: 3 }}>
          <Box maxWidth='350px' mx='auto' textAlign='center'>
            <Grid container spacing={3}>
              <Grid size={12}>
                <Typography variant='h1'>Error</Typography>
              </Grid>
              <Grid size={12}>
                <Typography variant='body2'>
                  {t('Oops, something went wrong. Please try again later.')}
                </Typography>
              </Grid>
              <Grid size={12}>
                <Button variant='contained' disableElevation onClick={handleReload}>
                  {t('Try Again')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
