// material-ui
import { Box, FormControl, FormControlLabel, FormLabel, Grid2 as Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project import
import SubCard from '../../../../ui-components/extended/card/SubCard'
import { UserBranchRole } from '../../../../types/UserBranchRole'

// ========================|| BRANCH USER - FORM ||======================== //

export default function BranchUserCreateForm() {
  // hooks
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid size={12}>
        <Controller
          name='email'
          control={control}
          rules={{
            required: 'This field is required.',
            pattern: {
              value: /^[^@]+@[^@]+$/,
              message: 'Must be a valid email.',
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id='email'
              label='Email'
              fullWidth
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? t(error.message as string) : null}
              slotProps={{
                input: {
                  autoComplete: 'off'
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='role'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <FormLabel component='legend' id='role-group-label' focused={false} error={!!error} sx={{ fontWeight: 500, mb: '8px' }}>
                {t('Role')}
              </FormLabel>
              <RadioGroup aria-labelledby='role-group-label'>
                <Grid container spacing={1}>
                  <Grid size={12}>
                    <SubCard content={false} sx={{ p: 1 }}>
                      <FormControlLabel
                        control={<Radio size='small' sx={{ ml: '6px' }} />}
                        value={UserBranchRole.ADMIN}
                        checked={value === UserBranchRole.ADMIN}
                        onChange={onChange}
                        disableTypography
                        label={
                          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Typography variant='subtitle1'>{t('Admin')}</Typography>
                            <Typography variant='caption'>{t('Can view and edit branch bookings')}</Typography>
                          </Box>
                        }
                      />
                    </SubCard>
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  )
}
