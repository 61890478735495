import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import ServiceAssistantApi from '../../api/serviceassistant/ServiceAssistantApi'
import { ServiceAssistant, ServiceAssistantParams } from '../../types/ServiceAssistant'
import { ApiError } from '../../types/ApiError'

// apis
const serviceAssistantApi = new ServiceAssistantApi()

// ========================|| HOOK - SERVICE ASSISTANTS ||======================== //

export default function useServiceAssistants(serviceId?: string, serviceAssistantParams: ServiceAssistantParams = {}) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [serviceAssistants, setServiceAssistants] = useState<ServiceAssistant[]>([])
  const [params, setParams] = useState(serviceAssistantParams)

  useEffect(() => {
    if (serviceId) {
      setLoading(true)
      auth!.getIdToken().then(token => {
        serviceAssistantApi
          .findAll(token, serviceId, params)
          .then(data => setServiceAssistants(data))
          .catch(error => setError(error))
          .finally(() => setLoading(false))
      })
    } else {
      setLoading(false)
    }
  }, [serviceId, params])

  return { loading, error, serviceAssistants, params, setServiceAssistants, setParams }
}
