// material-ui
import { Autocomplete, Box, FormControl, Grid2 as Grid, TextField } from '@mui/material'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { useTranslation } from 'react-i18next'

// project imports
import { countries } from '../../../types/Country'

// ========================|| AUTHENTICATION - SIGN UP - FORM ||======================== //

export default function SignUpForm() {
  // hooks
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Your full name is too short.' },
            maxLength: { value: 100, message: 'Your full name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('Full name')}
              fullWidth
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? t(error.message as string) : null}
            />
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='country'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <Autocomplete
                options={countries}
                value={value || null}
                onChange={(e, country) => onChange(country)}
                disableClearable
                isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading='lazy'
                      width='20'
                      src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
                      alt=''
                    />
                    {option.label} ({option.id})
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('Country')}
                    error={!!error}
                    helperText={error ? t(error.message as string) : t('You will not be able to update this field later.')}
                    slotProps={{
                      htmlInput: {
                        ...params.inputProps,
                        autoComplete: 'off',
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='phone'
          control={control}
          rules={{ validate: matchIsValidTel }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <MuiTelInput
              label={t('Phone number')}
              fullWidth
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? t('Invalid phone number.') : null}
              defaultCountry='AR'
              sx={{
                '& .MuiTelInput-IconButton': {
                  height: '44px',
                  width: '44px',
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
