// material-ui
import { Box, Grid2 as Grid } from '@mui/material'

// project imports
import Section from '../Section/Section'
import PriceCard from './PriceCard/PriceCard'

const tier = {
  title: 'Pagá por lo que usás',
  description: 'Ideal para la gran mayoria de negocios, emprendimientos y emprendedores.',
  price: 10000,
  aditional: 2000,
  features: [
    'Turnos ilimitados',
    'Creá hasta 10 sucursales',
    'Creá hasta 100 servicios',
    'Recordatorios por WhatsApp',
    'Recordatorios por mail automatizados',
    'Sumá colaboradores',
    'Exigí señas a través de Mercado Pago',
    'Asistencia técnica',
  ],
  link: '/signin',
}

export default function Pricing() {
  return (
    <>
      <Box id='pricing' height='80px' />
      <Section
        title='Precio'
        subtitle='Único plan, todo incluido'
        description='Pagá únicamente por la cantidad asistentes que requieran agendamiento.'
        id='pricing2'
        sx={{ backgroundColor: '#2196f3', py: '80px' }}
        colorWhite
      >
        <Grid size={{ xs: 12, md: 6 }} container spacing={3}>
          <PriceCard
            title={tier.title}
            description={tier.description}
            price={tier.price}
            aditional={tier.aditional}
            features={tier.features}
          />
        </Grid>
      </Section>
    </>
  )
}
