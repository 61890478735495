// material-ui
import { Button, Grid2 as Grid, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import CompanyAvatar from '../../../../ui-components/extended/avatar/CompanyAvatar'
import { Company } from '../../../../types/Company'
import { useNavigate } from 'react-router-dom'

// ========================|| COMPANY - LIST ITEM ||======================== //

interface Props {
  company: Company
}

export default function CompanyListItem({ company }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/${company.name}`)
  }

  return (
    <Grid container spacing={3}>
      <Grid
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        size={12}>
        <Grid container spacing={2} display='flex' alignItems='center' sx={{ flexFlow: 'nowrap' }}>
          <Grid>
            <CompanyAvatar active={company.active} />
          </Grid>
          <Grid>
            <Typography variant='subtitle1' align='left' whiteSpace='nowrap'>
              {company.displayName}
            </Typography>
            <Typography variant='caption' align='left' whiteSpace='nowrap'>
              @{company.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12}>
        <Button variant='outlined' size='small' fullWidth disabled={!company.active} startIcon={<AddIcon fontSize='small' />} onClick={handleClick}>
          {t('New booking')}
        </Button>
      </Grid>
    </Grid>
  )
}
