import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid2 as Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// project imports
import ClientApi from '../../../../api/client/ClientApi'
import ClientsContext from '../../../../context/client/ClientsContext'
import useAuth from '../../../../context/auth/useAuth'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import ClientEditForm from '../ClientEditForm/ClientEditForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Client, ClientUpdate } from '../../../../types/Client'

// ========================|| CLIENT - UPDATE DIALOG ||======================== //

const clientApi = new ClientApi()

interface Props extends DialogProps {
  client: Client
  onClose: () => void
}

export default function ClientEditDialog({ client, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setClients } = useContext(ClientsContext)
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<ClientUpdate>({
    defaultValues: {
      name: client.name,
      depositEnabled: client.depositEnabled,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (updated: Client) => {
    setClients(clients => ({ ...clients, content: clients.content.map(client => (client.id === updated.id ? updated : client)) }))

    dispatch(
      setSnackbar({
        message: t('Client updated successfully'),
        severity: 'success',
        open: true,
      }),
    )

    onClose()

    methods.reset({
      name: updated.name,
      depositEnabled: updated.depositEnabled,
    })
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating client'),
        severity: 'error',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<ClientUpdate> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      clientApi
        .update(token, client.id, {
          name: form.name,
          depositEnabled: form.depositEnabled,
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Edit client')}</DialogTitle>
          <DialogContent>
            <ClientEditForm client={client} />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid size={12} container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <LoadingButton type='submit' variant='contained' size='large' loading={loading} disabled={!methods.formState.isDirty} fullWidth disableElevation>
                  {t('Update')}
                </LoadingButton>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
