import React, { useContext } from 'react'

// material-ui
import { Grid2 as Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

// project imports
import { useTranslation } from 'react-i18next'

// project imports
import CompaniesContext from '../../../../context/company/CompaniesContext'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import MainTablePagination from '../../../../ui-components/extended/table/MainTablePagination'
import CompanyStatusChip from '../../../../ui-components/extended/chip/CompanyStatusChip'
import CompanyAvatar from '../../../../ui-components/extended/avatar/CompanyAvatar'
import CompanyBookButton from '../CompanyBookButton/CompanyBookButton'

// ========================|| COMPANY - TABLE ||======================== //

export default function CompanyTable() {
  const { t } = useTranslation()
  const { companies, params, setParams } = useContext(CompaniesContext)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead sx={{ borderTop: 0 }}>
            <TableRow>
              <TableCell>{t('Company')}</TableCell>
              <TableCell align='center'>{t('Status')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.content.map(company => (
              <TableRow key={company.id}>
                <TableCell>
                  <Grid container spacing={2} display='flex' alignItems='center' sx={{ flexFlow: 'nowrap' }}>
                    <Grid>
                      <CompanyAvatar active={company.active} />
                    </Grid>
                    <Grid>
                      <Typography variant='subtitle1' align='left' whiteSpace='nowrap'>
                        {company.displayName}
                      </Typography>
                      <Typography variant='caption' align='left' whiteSpace='nowrap'>
                        @{company.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align='center'>
                  <CompanyStatusChip active={company.active} />
                </TableCell>
                <TableCell align='center'>
                  <CompanyBookButton company={company} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <MainTablePagination count={companies.page.totalElements} pageable={params} setPageable={setParams} />
    </>
  )
}
