// project imports
import { axiosInstance } from '../../axios'
import { Client } from '../../types/Client'
import { ClientUpdateDto } from './dto/ClientUpdateDto'

// ========================|| API - CLIENT ||======================== //

export default class ClientApi {
  update(token: string, clientId: string, clientUpdate: ClientUpdateDto): Promise<Client> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/clients/${clientId}`, clientUpdate, config)
  }

  delete(token: string, clientId: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/clients/${clientId}`, config)
  }
}
