import { useContext, useState } from 'react'

// third-party
import { useTranslation } from 'react-i18next'

// material-ui
import { Fab, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/AddTwoTone'

// project imports
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import CompanyStatsContext from '../../../../context/companystats/CompanyStatsContext'
import AssistantCreateDialog from '../AssistantCreateDialog/AssistantCreateDialog'
import { isInactive } from '../../../../utils/subscription'

// ========================|| ASSISTANT - CREATE BUTTON ||======================== //

export default function AssistantCreateButton() {
  // hooks
  const { t } = useTranslation()
  const { stats } = useContext(CompanyStatsContext)
  const { subscription } = useContext(OptionalSubscriptionContext)

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const tooltipTitle = () => {
    if (isInactive(subscription)) {
      return 'An active subscription is required to create assistants.'
    }

    if (subscription && subscription.assistants === stats.assistants) {
      return 'Assistant limit reached. Please upgrade your subscription to create more assistants.'
    }

    return 'Create assistant'
  }

  const isDisabled = isInactive(subscription) || (subscription && subscription.assistants === stats.assistants)

  return (
    <>
      <Tooltip title={t(tooltipTitle())}>
        <span>
          <Fab
            color='primary'
            aria-label='Create assistant'
            sx={{
              width: '32px',
              height: '32px',
              minHeight: '32px',
              boxShadow: 'none',
            }}
            disabled={isDisabled}
            onClick={handleOpen}
          >
            <AddIcon fontSize='small' />
          </Fab>
        </span>
      </Tooltip>
      <AssistantCreateDialog open={open} onClose={handleClose} />
    </>
  )
}
