import React, { useContext, useState } from 'react'

// material-ui
import { Autocomplete, Box, Divider, Grid2 as Grid, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import SubscriptionApi from '../../../../api/subscription/CompanySubscriptionApi'
import PlanContext from '../../../../context/plan/PlanContext'
import BillingContext from '../../../../context/billing/BillingContext'
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import SubscriptionPricingCard from '../SubscriptionPricingCard/SubscriptionPricingCard'
import BillingInformationRequiredDialog from '../../Billing/BillingInformationRequiredDialog/BillingInformationRequiredDialog'
import { redirectToMercadoPagoCheckout } from '../../../../utils/mercadopago'
import { EXCEPTION_SUBSCRIPTION_DOWNGRADE } from '../../../../api/exceptions/exceptions'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Subscription } from '../../../../types/Subscription'
import { ApiError } from '../../../../types/ApiError'

// apis
const subscriptionApi = new SubscriptionApi()

// ========================|| SUBSCRIPTION - CREATE FORM ||======================== //

interface FormValues {
  assistants: string
  payerEmail: string
}

const numberOfAssistants = Array.from({ length: 50 }, (_, i) => i + 1).map(number => number.toString())

export default function SubscriptionCreateForm() {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { plan } = useContext(PlanContext)
  const { billing } = useContext(BillingContext)
  const { setSubscription } = useContext(OptionalSubscriptionContext)
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  // react-hook-form
  const { control, watch, formState, handleSubmit, reset, setError } = useForm<FormValues>({
    defaultValues: {
      assistants: '1',
      payerEmail: '',
    },
  })

  const assistants = parseInt(watch('assistants'))

  const handleSuccess = (subscription: Subscription) => {
    redirectToMercadoPagoCheckout(subscription.externalReference)

    reset({ assistants: subscription.assistants.toString() })

    setSubscription(subscription)

    dispatch(
      setSnackbar({
        message: t('Subscription created successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = (error: ApiError) => {
    if (error.status === 409 && error.message === EXCEPTION_SUBSCRIPTION_DOWNGRADE) {
      setError('assistants', { message: EXCEPTION_SUBSCRIPTION_DOWNGRADE })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while creating subscription'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    setLoading(false)
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    if (!billing) {
      setOpen(true)
      return
    }

    auth!.getIdToken().then(token => {
      setLoading(true)
      subscriptionApi
        .create(token, companyId, {
          assistants: parseInt(form.assistants),
          payerEmail: form.payerEmail,
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={3}>
        <Grid size={12}>
          <Controller
            name='assistants'
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={numberOfAssistants}
                value={value}
                fullWidth
                disableClearable
                onChange={(e, numberOfAssistants) => onChange(numberOfAssistants)}
                getOptionLabel={value => value.toString()}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('Number of assistants') as string}
                    error={!!error}
                    helperText={error ? t(error.message as string) : null}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <SubscriptionPricingCard plan={plan} assistants={assistants} />
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <Controller
            name='payerEmail'
            control={control}
            rules={{
              required: 'This field is required.',
              pattern: {
                value: /^[^@]+@[^@]+$/,
                message: 'Must be a valid email.',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id='email'
                label={t('Mercado Pago Email') as string}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={
                  error
                    ? t(error.message as string)
                    : t("If you don't have a Mercado Pago account, use your login email and pay with debit or credit card.")
                }
                slotProps={{
                  input: {
                    autoComplete: 'off'
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <LoadingButton type='submit' variant='contained' disabled={!formState.isDirty} loading={loading}>
            {t('Purchase')}
          </LoadingButton>
          <BillingInformationRequiredDialog open={open} />
        </Grid>
      </Grid>
    </Box>
  )
}
