// material-ui
import { Grid2 as Grid, TextField, useMediaQuery, useTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Dayjs } from 'dayjs'

// project imports
import TimeAutocomplete from '../../../../../ui-components/extended/autocomplete/TimeAutocomplete/TimeAutocomplete'
import ServiceAssistantDatePicker from '../../../../../ui-components/extended/datepicker/ServiceAssistantDatePicker'
import { locale } from '../../../../../utils/locale'
import { findTimezoneLabel } from '../../../../../utils/timezone'
import { Branch } from '../../../../../types/Branch'
import { ServiceDetails } from '../../../../../types/Service'
import { ServiceAssistant } from '../../../../../types/ServiceAssistant'

// ========================|| BOOKING FORM - SELECT DATE ||======================== //

export default function SelectDateStep() {
  // hooks
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { t, i18n } = useTranslation()
  const { control, watch } = useFormContext()

  // state
  const branch: Branch = watch('branch')
  const service: ServiceDetails = watch('service')
  const assistant: ServiceAssistant = watch('assistant')
  const date: Dayjs = watch('date')

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 6 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale(i18n.language)}>
          <Controller
            name='date'
            control={control}
            rules={{ required: 'This field is required.' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <ServiceAssistantDatePicker
                service={service}
                assistant={assistant}
                value={value}
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: error ? t(error.message as string) : null,
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Controller
          name='time'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TimeAutocomplete
              service={service}
              assistant={assistant}
              date={date}
              value={value}
              onChange={(e, time) => onChange(time)}
              renderInput={params =>
                matchesXS ? (
                  <TextField
                    {...params}
                    placeholder={t('Hour')}
                    error={!!error}
                    helperText={error ? t(error.message as string) : findTimezoneLabel(branch.address.timezone)}
                  />
                ) : (
                  <TextField
                    {...params}
                    label={t('Hour')}
                    error={!!error}
                    helperText={error ? t(error.message as string) : findTimezoneLabel(branch.address.timezone)}
                  />
                )
              }
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
