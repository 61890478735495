import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid2 as Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import CompanyUsersContext from '../../../../context/usercompany/CompanyUsersContext'
import CompanyUserApi from '../../../../api/usercompany/CompanyUserApi'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import CompanyUserCreateForm from '../CompanyUserCreateForm/CompanyUserCreateForm'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { CompanyUser } from '../../../../types/CompanyUser'
import { UserCompanyRole } from '../../../../types/UserCompanyRole'
import { ApiError } from '../../../../types/ApiError'
import {
  EXCEPTION_SUBSCRIPTION_REQUIRED,
  EXCEPTION_USER_COMPANY_ALREADY_EXIST,
  EXCEPTION_USER_NOT_FOUND,
} from '../../../../api/exceptions/exceptions'

// apis
const companyUserApi = new CompanyUserApi()

// ========================|| COMPANY USER - CREATE DIALOG ||======================== //

interface FormValues {
  email: string
  role: UserCompanyRole
}

interface Props extends DialogProps {
  onClose: () => void
}

export default function CompanyUserCreateDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setCompanyUsers } = useContext(CompanyUsersContext)
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      role: UserCompanyRole.ADMIN,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (companyUser: CompanyUser) => {
    setCompanyUsers(companyUsers => companyUsers.concat(companyUser))

    dispatch(
      setSnackbar({
        message: t('User added successfully'),
        severity: 'success',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_USER_NOT_FOUND) {
      methods.setError('email', { message: EXCEPTION_USER_NOT_FOUND })
      return
    }

    if (error.message === EXCEPTION_USER_COMPANY_ALREADY_EXIST) {
      methods.setError('email', { message: 'The user is already associated with the company.' })
      return
    }

    if (error.message === EXCEPTION_SUBSCRIPTION_REQUIRED) {
      dispatch(setSnackbar({ message: t(EXCEPTION_SUBSCRIPTION_REQUIRED), severity: 'error', open: true }))
    }

    else {
      dispatch(setSnackbar({ message: t('An unexpected error occurred while adding user'), severity: 'error', open: true }))
    }

    onClose()
    methods.reset()
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      companyUserApi
        .create(token, companyId, {
          email: form.email,
          role: form.role,
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Invite User')}</DialogTitle>
          <DialogContent>
            <CompanyUserCreateForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid size={12} container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <LoadingButton type='submit' variant='contained' size='large' loading={loading} fullWidth disableElevation>
                  {t('Invite')}
                </LoadingButton>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
