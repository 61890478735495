// material-ui
import { TextField } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import ClientAutocomplete from '../../../../../ui-components/extended/autocomplete/ClientAutocomplete/ClientAutocomplete'
import { BookingCreateForm } from '../../../../../types/Booking'

// ========================|| BOOKING - FORM - SELECT CLIENT STEP ||======================== //

export default function SelectClientStep() {
  const { t } = useTranslation()
  const { control, resetField } = useFormContext<BookingCreateForm>()

  return (
    <Controller
      name='client'
      control={control}
      rules={{ required: 'This field is required.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ClientAutocomplete
          value={value}
          onChange={(e, client) => {
            resetField('assistant')
            resetField('date')
            resetField('time')
            return onChange(client)
          }}
          renderInput={params => <TextField {...params} label={t('Client')} error={!!error} helperText={error ? t(error.message as string) : null} />}
        />
      )}
    />
  )
}