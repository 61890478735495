import React, { useContext } from 'react'

// material-ui
import { Stack, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ClientsContext from '../../../../context/client/ClientsContext'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import MainTablePagination from '../../../../ui-components/extended/table/MainTablePagination'
import ClientDepositChip from '../ClientDepositChip/ClientDepositChip'
import ClientEditIconButton from '../ClientEditIconButton/ClientEditIconButton'
import ClientDeleteIconButton from '../ClientDeleteIconButton/ClientDeleteIconButton'
import ClientWhatsAppIconButton from '../ClientWhatsAppIconButton/ClientWhatsAppIconButton'

// ========================|| CLIENT - TABLE ||======================== //

export default function ClientTable() {
  // hooks
  const { t } = useTranslation()
  const { clients, params, setParams } = useContext(ClientsContext)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Name')}</TableCell>
              <TableCell align='right'>{t('Bookings')}</TableCell>
              <TableCell align='right'>{t('Cancellations')}</TableCell>
              <TableCell align='center'>{t('Deposits')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.content.map(client => (
              <TableRow key={client.id}>
                <TableCell>
                  <Stack direction='row' spacing={0.5} alignItems='center'>
                    <Typography variant='subtitle1'>{client.name}</Typography>
                    {client.user && <CheckCircleIcon sx={{ color: 'success.dark', width: '14px', height: '14px' }} />}
                  </Stack>
                  {client.user && <Typography variant='caption'>{client.user.email}</Typography>}
                </TableCell>
                <TableCell align='right'>{client.totalBookings}</TableCell>
                <TableCell align='right'>{client.totalCancellations}</TableCell>
                <TableCell align='center'>
                  <ClientDepositChip client={client} />
                </TableCell>
                <TableCell align='center' width={180}>
                  <ClientEditIconButton client={client} />
                  <ClientDeleteIconButton client={client} />
                  <ClientWhatsAppIconButton client={client} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <MainTablePagination count={clients.page.totalElements} pageable={params} setPageable={setParams} />
    </>
  )
}
