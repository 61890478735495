import React, { useContext, useState } from 'react'

// material-ui
import { Box, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid2 as Grid, Typography, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import AssistantBusinessHoursApi from '../../../../api/businesshour/AssistantBusinessHoursApi'
import BusinessHoursContext from '../../../../context/businesshour/BusinessHoursContext'
import GreenSwitch from '../../../../ui-components/extended/switch/GreenSwitch'
import useAuth from '../../../../context/auth/useAuth'
import useAssistantId from '../../../../hooks/assistant/useAssistantId'
import BusinessHoursFormControl from '../../BusinessHours/BusinessHoursFormControl/BusinessHoursFormControl'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { DEFAULT_BUSINESS_HOURS, BusinessHour } from '../../../../types/BusinessHour'

// apis
const businessHoursApi = new AssistantBusinessHoursApi()

// ========================|| TAB - COMPANY - BUSINESS HOURS FORM ||======================== //

interface FormValues {
  customBusinessHours: boolean
  businessHours: BusinessHour[]
}

export default function AssistantBusinessHoursForm() {
  // hooks
  const theme = useTheme()
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { businessHours, setBusinessHours } = useContext(BusinessHoursContext)
  const assistantId = useAssistantId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      customBusinessHours: businessHours.length > 0,
      businessHours: businessHours.length > 0 ? businessHours : [],
    },
  })

  // state
  const [loading, setLoading] = useState(false)
  const customBusinessHours = methods.watch('customBusinessHours')

  const handleChangeBusinessHours = () => {
    if (businessHours.length > 0) {
      methods.setValue('businessHours', businessHours, { shouldDirty: true })
    } else {
      methods.setValue('businessHours', DEFAULT_BUSINESS_HOURS, { shouldDirty: true })
    }
  }

  const handleSuccess = (businessHours: BusinessHour[]) => {
    setBusinessHours(businessHours)

    methods.reset({
      customBusinessHours: businessHours.length > 0,
      businessHours: businessHours.length > 0 ? businessHours : [],
    })

    dispatch(
      setSnackbar({
        message: t('Business hours updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating business hours'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      businessHoursApi.update(token, assistantId, form)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          setLoading(false)
        })
    })
  }

  return (
    <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} sx={{ [theme.breakpoints.only('xs')]: { width: 'max-content' } }}>
      <Grid container spacing={3}>
        <Grid size={12}>
          <FormProvider {...methods}>
            <Grid container spacing={3}>
              <Grid size={12}>
                <Controller
                  name='customBusinessHours'
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl component='fieldset' variant='standard' fullWidth>
                      <FormLabel component='legend'>
                        <Typography variant='subtitle1'>{t('Custom business hours')}</Typography>
                      </FormLabel>
                      <FormControlLabel
                        control={<GreenSwitch id='customBusinessHours' />}
                        value={value}
                        checked={value}
                        onChange={(event, checked) => {
                          if (checked) {
                            handleChangeBusinessHours()
                          } else {
                            methods.setValue('businessHours', [], { shouldDirty: true })
                          }
                          return onChange(event, checked)
                        }}
                        label={t('Use custom business hours for this assistant')}
                      />
                      <FormHelperText>
                        {value ? t('When enabled, the assistant has its own business hours.') : t('When disabled, the assistant use the branch business hours.')}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {customBusinessHours && (
                <>
                  <Grid size={12}>
                    <Divider />
                  </Grid>
                  <Grid size={12}>
                    <BusinessHoursFormControl />
                  </Grid>
                </>
              )}
            </Grid>
          </FormProvider>
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <LoadingButton variant='contained' type='submit' disabled={!methods.formState.isDirty} loading={loading}>
            {t('Save changes')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}