import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid2 as Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import UserStatsContext from '../../../../context/userstats/UserStatsContext'
import UserCompaniesContext from '../../../../context/usercompany/UserCompaniesContext'
import UserCompanyApi from '../../../../api/usercompany/UserCompanyApi'
import useAuth from '../../../../context/auth/useAuth'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import UserCompanyForm from '../UserCompanyForm/UserCompanyForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { ApiError } from '../../../../types/ApiError'
import { UserCompany } from '../../../../types/UserCompany'
import { CompanyCreateForm } from '../../../../types/Company'
import { EXCEPTION_COMPANY_NAME_ALREADY_EXIST, EXCEPTION_COMPANY_NAME_RESERVED_KEYWORD } from '../../../../api/exceptions/exceptions'

// apis
const userCompanyApi = new UserCompanyApi()

// ========================|| USER COMPANY - CREATE DIALOG ||======================== //

interface Props extends DialogProps {
  onClose: () => void
}

export default function UserCompanyCreateDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(UserStatsContext)
  const { setUserCompanies } = useContext(UserCompaniesContext)
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<CompanyCreateForm>({
    defaultValues: {
      name: '',
      displayName: '',
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (userCompany: UserCompany) => {
    setUserCompanies(userCompanies => userCompanies.concat(userCompany))

    setStats({ ...stats, companies: stats.companies + 1 })

    dispatch(
      setSnackbar({
        message: t('Company created successfully'),
        severity: 'success',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_COMPANY_NAME_ALREADY_EXIST) {
      methods.setError('name', { message: EXCEPTION_COMPANY_NAME_ALREADY_EXIST })
      return
    }

    if (error.message === EXCEPTION_COMPANY_NAME_RESERVED_KEYWORD) {
      methods.setError('name', { message: EXCEPTION_COMPANY_NAME_RESERVED_KEYWORD })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while creating company'),
        severity: 'error',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<CompanyCreateForm> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      userCompanyApi
        .create(token, {
          name: form.name,
          displayName: form.displayName,
          country: form.country.id,
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Create Company')}</DialogTitle>
          <DialogContent>
            <UserCompanyForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid size={12} container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <LoadingButton type='submit' variant='contained' size='large' loading={loading} fullWidth disableElevation>
                  {t('Create')}
                </LoadingButton>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
