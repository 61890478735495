// material-ui
import { Grid2 as Grid } from '@mui/material'

// project imports
import AssistantBusinessHoursCard from '../AssistantBusinessHoursCard/AssistantBusinessHoursCard'
import AssistantBusinessHoursAdjustedCard from '../AssistantBusinessHoursAdjustedCard/AssistantBusinessHoursAdjustedCard'
import AssistantBusinessHoursProvider from '../../../../context/businesshour/AssistantBusinessHoursProvider'

// ========================|| ASSISTANT TABS - BUSINESS HOURS ||======================== //

export default function AssistantBusinessHoursTab() {
  return (
    <AssistantBusinessHoursProvider>
      <Grid container spacing={3}>
        <Grid size={12}>
          <AssistantBusinessHoursCard />
        </Grid>
        <Grid size={12}>
          <AssistantBusinessHoursAdjustedCard />
        </Grid>
      </Grid>
    </AssistantBusinessHoursProvider>
  )
}
