import React, { useContext } from 'react'

// material-ui
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid2 as Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'
import dayjs from 'dayjs'

// third-party
import ServiceContext from '../../../../context/service/ServiceContext'
import GreenSwitch from '../../../../ui-components/extended/switch/GreenSwitch'
import { ServiceAssistant } from '../../../../types/ServiceAssistant'
import { locale } from '../../../../utils/locale'

// ========================|| ASSISTANT SERVICE - FORM ||======================== //

interface Props {
  serviceAssistant: ServiceAssistant
}

export default function ServiceAssistantForm({ serviceAssistant }: Props) {
  const { i18n, t } = useTranslation()
  const { service } = useContext(ServiceContext)
  const { control, watch, setValue } = useFormContext()

  const customPrice = watch('customPrice')
  const disabledWeekdays: number[] = watch('disabledWeekdays')

  const isChecked = (dayOfWeek: number) => {
    if (!serviceAssistant.availableWeekdays.includes(dayOfWeek)) return false
    return !disabledWeekdays.includes(dayOfWeek)
  }

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      {service.price !== null && (
        <Grid size={12}>
          <Controller
            name='customPrice'
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl component='fieldset' variant='standard' fullWidth>
                <FormLabel component='legend' focused={false} sx={{ fontWeight: 500 }}>
                  {t('Custom price')}
                </FormLabel>
                <FormControlLabel
                  control={<GreenSwitch id='customPrice' />}
                  value={value}
                  checked={value}
                  onChange={(event, checked) => {
                    if (checked) {
                      if (serviceAssistant.price !== null) {
                        setValue('price', serviceAssistant.price.toString(), { shouldDirty: true })
                      } else if (service.price !== null) {
                        setValue('price', service.price.toString(), { shouldDirty: true })
                      }
                      if (serviceAssistant.currency) {
                        setValue('currency', serviceAssistant.currency, { shouldDirty: true })
                      } else {
                        setValue('currency', service.currency, { shouldDirty: true })
                      }
                    } else {
                      setValue('price', null, { shouldDirty: true })
                      setValue('currency', null, { shouldDirty: true })
                    }
                    return onChange(event, checked)
                  }}
                  label={t('Use a custom service price for this assistant')}
                />
              </FormControl>
            )}
          />
        </Grid>
      )}
      {customPrice && (
        <>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='price'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 0, message: 'Price must be greater than or equal to zero.' },
                pattern: { value: /^\d*$/, message: 'Price must be an integer number.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('Price')}
                  value={value}
                  onChange={e => {
                    const inputValue = e.target.value
                    // Removes any character that is not a number
                    const numericValue = inputValue.replace(/[^0-9]/g, '')

                    // Allow only integers up to 6 digits
                    if (/^\d{0,6}$/.test(numericValue)) {
                      onChange(numericValue)
                    }
                  }}
                  error={!!error}
                  helperText={error ? t(error.message as string) : null}
                  fullWidth
                  slotProps={{
                    input: { startAdornment: <InputAdornment position='start'>$</InputAdornment> }
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='currency'
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel id='currency-select-label'>{t('Currency')}</InputLabel>
                  <Select id='currency-select' labelId='currency-select-label' label={t('Currency')} value={value} onChange={onChange}>
                    <MenuItem value='ARS'>{t('(ARS) Argentine Peso')}</MenuItem>
                  </Select>
                  {error && <FormHelperText error>{t(error.message as string)}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
        </>
      )}
      <Grid size={12}>
        <FormControl fullWidth component='fieldset' variant='standard'>
          <FormLabel component='legend' focused={false} sx={{ fontWeight: 500 }}>
            {t('Available days of the week')}
          </FormLabel>
          <FormGroup>
            {[1, 2, 3, 4, 5, 6, 7].map(dayOfWeek => (
              <FormControlLabel
                key={dayOfWeek}
                checked={isChecked(dayOfWeek)}
                onChange={(event, checked) => {
                  if (checked) {
                    setValue(
                      'disabledWeekdays',
                      disabledWeekdays.filter(value => value !== dayOfWeek),
                      { shouldDirty: true },
                    )
                  } else {
                    setValue('disabledWeekdays', disabledWeekdays.concat(dayOfWeek), { shouldDirty: true })
                  }
                }}
                control={<Checkbox id={dayjs().day(dayOfWeek).locale(locale(i18n.language)).format('dddd')} />}
                label={
                  <Typography>
                    {capitalize(dayjs().day(dayOfWeek).locale(locale(i18n.language)).format('dddd'))}
                    {!serviceAssistant.availableWeekdays.includes(dayOfWeek) && (
                      <Typography component='span' variant='caption' ml={1}>
                        {`(${t('The assistant does not work this day')})`}
                      </Typography>
                    )}
                  </Typography>
                }
                disabled={!serviceAssistant.availableWeekdays.includes(dayOfWeek)}
              />
            ))}
          </FormGroup>
          <FormHelperText>{t('It allows to limit the days of the week that the assistant can provide the service.')}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  )
}
