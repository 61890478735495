// material-ui
import { Box, Button, Grid2 as Grid, TextField, useTheme } from '@mui/material'

// third-party
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// project imports
import Section from '../Section/Section'

interface FormValues {
  message: string
}

export default function Contact() {
  const theme = useTheme()
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      message: '',
    },
  })

  const submit = (form: FormValues) => {
    window.open(`https://wa.me/+541112345678?text=${form.message}`)
  }

  return (
    <>
      <Box id='contact' height='80px' />
      <Section
        id='contact-form'
        title='Contacto'
        subtitle='Hola, ¿Tenés preguntas?'
        description='Envianos un mensaje y te responderemos lo antes posible.'
        sx={{ py: '80px', backgroundColor: `${theme.palette.primary.main}1a` }}
      >
        <Box component='form' onSubmit={handleSubmit(submit)} width='100%'>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Controller
                control={control}
                rules={{
                  required: 'This field is required.',
                  minLength: { value: 20, message: 'Message is too short.' },
                  maxLength: { value: 200, message: 'Message is too long.' },
                }}
                name='message'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    placeholder='Mensaje'
                    fullWidth
                    multiline
                    rows={5}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? t(error.message as string) : null}
                  >
                    {t('Message')}
                  </TextField>
                )}
              />
            </Grid>
            <Grid size={12}>
              <Button type='submit' variant='contained' size='large' disableElevation disabled>
                {t('Enviar')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Section>
    </>
  )
}
