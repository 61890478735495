import { Fragment, useContext } from 'react'

// material-ui
import { CardContent, Divider, Grid2 as Grid, Pagination, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserCompaniesContext from '../../../../context/usercompany/UserCompaniesContext'
import useListPagination from '../../../../hooks/pagination/useListPagination'
import UserCompanyListItem from '../UserCompanyListItem/UserCompanyListItem'
import { UserCompany } from '../../../../types/UserCompany'

// ========================|| USER COMPANY - LIST ||======================== //

export default function UserCompanyList() {
  const { t } = useTranslation()
  const { loading, userCompanies, params } = useContext(UserCompaniesContext)
  const { count, page, handleChange } = useListPagination(userCompanies)

  if (loading) {
    return null;
  }

  if (count === 0) {
    if (params.search && params.search.length > 0) {
      return (
        <CardContent sx={{ padding: 3 }}>
          <Typography>{t('No companies found.')}</Typography>
        </CardContent>
      )
    }

    return (
      <CardContent sx={{ padding: 3 }}>
        <Typography>{t("You don't have companies created yet.")}</Typography>
      </CardContent>
    )
  }

  return (
    <CardContent sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        {userCompanies.map((userCompany: UserCompany) => (
          <Fragment key={userCompany.id}>
            <Grid size={12}>
              <UserCompanyListItem userCompany={userCompany} />
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Fragment>
        ))}
        <Grid display='flex' justifyContent='center' size={12}>
          <Pagination count={count} page={page} onChange={handleChange} />
        </Grid>
      </Grid>
    </CardContent>
  )
}
