// material-ui
import { Box, Button, Container, Divider, Grid2 as Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

// third-party
import { useTranslation } from 'react-i18next'

export default function Help() {
  // hooks
  const { t } = useTranslation()
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box pt='80px' id='help'>
      <Container>
        <Grid container spacing={6} alignItems='center'>
          <Grid size={{ xs: 12, md: 'grow' }} container spacing={2} display='flex'>
            <Grid size={12}>
              <Typography variant='h2' textAlign='center' fontWeight={600}>
                {t('¿Necesitas soporte?')}
              </Typography>
            </Grid>
            <Grid size={12} display='flex' justifyContent='center'>
              <Typography variant='body2' textAlign='center' color='text.primary' maxWidth={400}>
                {t('Contactanos si tenés dudas sobre la plataforma')}
              </Typography>
            </Grid>
            <Grid display='flex' justifyContent='center' size={12}>
              <Button variant='text' endIcon={<ArrowRightAltIcon />} href='/#contact'>
                {t('Contacto')}
              </Button>
            </Grid>
          </Grid>
          {!matchesMD && (
            <Grid>
              <Divider sx={{ borderWidth: '0px thin 0px 0px', height: '150px' }} />
            </Grid>
          )}
          <Grid size={{ xs: 12, md: 'grow' }} container spacing={2}>
            <Grid size={12}>
              <Typography variant='h2' textAlign='center' fontWeight={600}>
                {t('Personalizar plan')}
              </Typography>
            </Grid>
            <Grid size={12} display='flex' justifyContent='center'>
              <Typography variant='body2' textAlign='center' color='text.primary' maxWidth={400}>
                {t('¿Estás buscando algo diferente? Comentanos para revisar tu caso particular')}
              </Typography>
            </Grid>
            <Grid display='flex' justifyContent='center' size={12}>
              <Button variant='text' endIcon={<ArrowRightAltIcon />} href='/#contact'>
                {t('Contacto')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
