// material-ui
import { CardContent, Divider, Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material'

// project imports
import { useTranslation } from 'react-i18next'

// project imports
import MainCard from '../../../../ui-components/extended/card/MainCard'
import UserCompanySearchBar from '../UserCompanySearchBar/UserCompanySearchBar'
import UserCompanyCreateButton from '../UserCompanyCreateButton/UserCompanyCreateButton'
import UserCompanyTable from '../UserCompanyTable/UserCompanyTable'
import UserCompanyList from '../UserCompanyList/UserCompanyList'

// ========================|| USER COMPANY - CARD ||======================== //

export default function UserCompanyCard() {
  // hooks
  const { t } = useTranslation()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <MainCard title={t('Companies')} content={false} border={false}>
      <CardContent sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid size='grow'>
            <UserCompanySearchBar />
          </Grid>
          <Grid textAlign='center'>
            <UserCompanyCreateButton />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {matchesXS ? <UserCompanyList /> : <UserCompanyTable />}
    </MainCard>
  )
}