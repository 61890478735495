// project imports
import useTooltip from '../../../../hooks/company/useTooltip'
import useBreadcrumbs from '../../../../hooks/company/useBreadcrumbs'
import MainCard from '../../../../ui-components/extended/card/MainCard'
import Breadcrumbs from '../../../../ui-components/extended/breadcrumbs/Breadcrumbs'
import BookingForm from '../BookingForm/BookingForm'

// ========================|| BOOKING - CREATE FORM ||======================== //

export default function BookingCreateCard() {
  const items = useBreadcrumbs()
  const tooltip = useTooltip()

  return (
    <MainCard title={<Breadcrumbs items={items} tooltip={tooltip} />} border={false}>
      <BookingForm />
    </MainCard>
  )
}