import React, { useContext, useState } from 'react'

// material-ui
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel, FormGroup,
  FormHelperText,
} from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import ClientApi from '../../../../api/client/ClientApi'
import ClientsContext from '../../../../context/client/ClientsContext'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Client } from '../../../../types/Client'

// apis
const clientApi = new ClientApi()

// ========================|| CLIENT - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  client: Client
  onClose: () => void
}

export default function ClientDeleteDialog({ client, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setClients } = useContext(ClientsContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeClient = (clientId: string) => {
    setClients(clients => ({ ...clients, content: clients.content.filter(client => client.id !== clientId) }))
  }

  const handleSuccess = () => {
    removeClient(client.id)

    dispatch(
      setSnackbar({
        message: t('Client deleted successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while deleting client'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      clientApi.delete(token, client.id).then(handleSuccess).catch(handleError).finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-client-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-client-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          <Trans i18nKey='deleteClient' values={{ name: client.name }}>
            Do you want to delete <strong title={client.name} /> as a client?
          </Trans>
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox id='bookings' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('All pending and confirmed bookings will be canceled')}
            sx={{ cursor: 'text' }}
          />
          <FormHelperText error>{t('This action cannot be undone.')}</FormHelperText>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
