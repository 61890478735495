import { useContext } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Chip, Grid2 as Grid, useMediaQuery } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import BookingFilterTag from '../BookingFilterTag/BookingFilterTag'
import { Assistant } from '../../../../types/Assistant'

// ========================|| BOOKING - FILTER TAGS - ASSISTANT ||======================== //

export default function BookingFilterAssistantTag() {
  const { t } = useTranslation()
  const { params, setParams } = useContext(BookingsContext)
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  const handleDelete = (deleted: Assistant) => {
    setParams(filter => ({
      ...filter,
      assistants: filter.assistants.filter(assistant => assistant.id !== deleted.id),
      page: 0,
    }))
  }

  return (
    <BookingFilterTag title={t('Assistants')}>
      <Grid container spacing={1}>
        {params.assistants.map(assistant => (
          <Grid key={assistant.id}>
            <Chip
              label={assistant.name}
              color='secondary'
              size={matchesXS ? 'small' : 'medium'}
              onDelete={() => handleDelete(assistant)}
            />
          </Grid>
        ))}
      </Grid>
    </BookingFilterTag>
  )
}