// project imports
import { axiosInstance } from '../../axios'
import { UserBookingCreateDto } from './dto/UserBookingCreateDto'
import { Booking, BookingParams } from '../../types/Booking'
import { Page } from '../../types/Page'

// ========================|| API - USER BOOKING ||======================== //

export default class UserBookingApi {
  findAll(token: string, params: BookingParams): Promise<Page<Booking>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        startDate: params.startDate?.toDate(),
        endDate: params.endDate?.toDate(),
        status: params.status,
        search: params.search,
        page: params.page,
        size: params.size,
        sort: params.sort,
      },
    }

    return axiosInstance.get('/api/v1/users/me/bookings', config)
  }

  create(token: string, booking: UserBookingCreateDto): Promise<Booking> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post('/api/v1/users/me/bookings', booking, config)
  }

  cancel(token: string, bookingId: string): Promise<Booking> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/users/me/bookings/${bookingId}/cancel`, {}, config)
  }
}
