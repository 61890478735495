import { useContext } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Grid2 as Grid, useMediaQuery } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import BookingStatusChip from '../../../../ui-components/extended/chip/BookingStatusChip'
import BookingFilterTag from '../BookingFilterTag/BookingFilterTag'
import { BookingStatus } from '../../../../types/Booking'

// ========================|| BOOKING - FILTER TAGS - STATUS ||======================== //

export default function BookingFilterStatusTag() {
  const { t } = useTranslation()
  const { params, setParams } = useContext(BookingsContext)
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  const handleDelete = (deleted: BookingStatus) => {
    setParams(filter => ({
      ...filter,
      status: filter.status.filter(status => status !== deleted),
      page: 0,
    }))
  }

  return (
    <BookingFilterTag title={t('Status')}>
      <Grid container spacing={1}>
        {params.status.map(status => (
          <Grid key={status}>
            <BookingStatusChip
              status={status}
              color='primary'
              size={matchesXS ? 'small' : 'medium'}
              onDelete={() => handleDelete(status)}
            />
          </Grid>
        ))}
      </Grid>
    </BookingFilterTag>
  )
}