import { useContext } from 'react'

// material-ui
import { Box, Grid2 as Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import IntegrationContext from '../../../../context/integration/IntegrationContext'
import IntegrationMercadoPagoDisconnectButton from '../IntegrationMercadoPagoDisconnectButton/IntegrationMercadoPagoDisconnectButton'
import IntegrationMercadoPagoConnectButton from '../IntegrationMercadoPagoConnectButton/IntegrationMercadoPagoConnectButton'
import IntegrationCard from '../IntegrationCard/IntegrationCard'
import IconCard from '../IconCard/IconCard'

// assets
import { ReactComponent as MercadoPagoIcon } from '../../../../assets/images/mercadopago.svg'

// ========================|| INTEGRATIONS - MERCADO PAGO - UNLINK CARD ||======================== //

export default function IntegrationMercadoPagoCard() {
  const { t } = useTranslation()
  const { integration } = useContext(IntegrationContext)

  return (
    <IntegrationCard>
      <Grid container spacing={2} direction='column' flexWrap='nowrap'>
        <Grid>
          <IconCard>
            <MercadoPagoIcon width={48} height={48} />
          </IconCard>
        </Grid>
        <Grid size='grow'>
          <Box>
            <Typography variant='subtitle1' display='flex' alignItems='center'>
              Mercado Pago
            </Typography>
            <Typography variant='subtitle2'>{t('Connect your Mercado Pago account to start receiving payments from your customers.')}</Typography>
          </Box>
        </Grid>
        <Grid>{integration.mercadoPago ? <IntegrationMercadoPagoDisconnectButton /> : <IntegrationMercadoPagoConnectButton />}</Grid>
      </Grid>
    </IntegrationCard>
  )
}
