import { useContext } from 'react'

// material-ui
import { Grid2 as Grid } from '@mui/material'

// project imports
import PaymentsContext from '../../../../context/payment/PaymentsContext'
import BillingInformationCard from '../BillingInformationCard/BillingInformationCard'
import BillingHistoryCard from '../BillingHistoryCard/BillingHistoryCard'

// ========================|| BILLING - CONTAINER ||======================== //

export default function BillingContainer() {
  const { payments } = useContext(PaymentsContext)

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <BillingInformationCard />
      </Grid>
      {payments.content.length > 0 && (
        <Grid size={12}>
          <BillingHistoryCard />
        </Grid>
      )}
    </Grid>
  )
}