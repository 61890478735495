import { useEffect } from 'react'

// material-ui
import { Grid2 as Grid } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// project imports
import IntegrationMercadoPagoCard from './IntegrationMercadoPagoCard/IntegrationMercadoPagoCard'
import IntegrationProvider from './IntegrationProvider'
import IntegrationGoogleCard from './IntegrationGoogleCard/IntegrationGoogleCard'
import { setSnackbar } from '../../../store/snackbar/reducer'

// ========================|| INTEGRATIONS ||======================== //

export default function Integration() {
  const { t } = useTranslation()
  const [params, setParams] = useSearchParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const status = params.get('status')
    const message = params.get('message')

    if (status !== 'success' && status !== 'error') {
      return
    }

    if (status && message) {
      dispatch(
        setSnackbar({
          message: t(message) as string,
          severity: status,
          open: true,
        }),
      )
      params.delete('status')
      params.delete('message')
      setParams(params)
    }
  }, [params])

  return (
    <IntegrationProvider>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <IntegrationMercadoPagoCard />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <IntegrationGoogleCard />
        </Grid>
      </Grid>
    </IntegrationProvider>
  )
}
