import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid2 as Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import AssistantUsersContext from '../../../../context/userassistant/AssistantUsersContext'
import AssistantUserApi from '../../../../api/userassistant/AssistantUserApi'
import useAuth from '../../../../context/auth/useAuth'
import useAssistantId from '../../../../hooks/assistant/useAssistantId'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import AssistantUserEditForm from '../AssistantUserEditForm/AssistantUserEditForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { AssistantUser } from '../../../../types/AssistantUser'
import { UserAssistantRole } from '../../../../types/UserAssistantRole'

// apis
const assistantUserApi = new AssistantUserApi()

// ========================|| ASSISTANT USER - EDIT DIALOG ||======================== //

interface FormValues {
  role: UserAssistantRole
}

interface Props extends DialogProps {
  assistantUser: AssistantUser
  onClose: () => void
}

export default function AssistantUserEditDialog({ assistantUser, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setAssistantUsers } = useContext(AssistantUsersContext)
  const assistantId = useAssistantId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    defaultValues: {
      role: assistantUser.role,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (updated: AssistantUser) => {
    setAssistantUsers(assistantUsers => ({
      ...assistantUsers,
      content: assistantUsers.content.map(assistantUser =>
        assistantUser.id === updated.id && assistantUser.id === updated.id ? updated : assistantUser,
      ),
    }))

    methods.reset({
      role: updated.role,
    })

    dispatch(
      setSnackbar({
        message: t('User updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating user'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    methods.reset()
    setLoading(false)
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      assistantUserApi.update(token, assistantId, assistantUser.id, form).then(handleSuccess).catch(handleError).finally(handleCloseForm)
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Edit User')}</DialogTitle>
          <DialogContent>
            <AssistantUserEditForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  size='large'
                  loading={loading}
                  disabled={!methods.formState.isDirty}
                  fullWidth
                  disableElevation
                >
                  {t('Update')}
                </LoadingButton>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
