import { Fragment, useState } from 'react'

// material-ui
import { Box, Button, Step, StepLabel, Stepper } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { useTranslation } from 'react-i18next'
import { FieldPath, useFormContext } from 'react-hook-form'

// project imports
import SelectClientStep from '../BookingForm/SelectClientStep/SelectClientStep'
import SelectServiceStep from '../BookingForm/SelectServiceStep/SelectServiceStep'
import SelectAssistantStep from '../BookingForm/SelectAssistantStep/SelectAssistantStep'
import SelectDateStep from '../BookingForm/SelectDateStep/SelectDateStep'
import SummaryStep from '../BookingForm/SummaryStep/SummaryStep'
import { BookingCreateForm } from '../../../../types/Booking'

// ========================|| BOOKING - STEPPER ||======================== //

const steps = [
  { label: 'Client', component: <SelectClientStep />, fields: ['client'] },
  { label: 'Service', component: <SelectServiceStep />, fields: ['service'] },
  { label: 'Assistant', component: <SelectAssistantStep />, fields: ['assistant'] },
  { label: 'Date', component: <SelectDateStep />, fields: ['date', 'time'] },
  { label: 'Summary', component: <SummaryStep />, fields: [] },
]

interface Props {
  loading: boolean
}

export default function BookingStepper({ loading }: Props) {
  const { t } = useTranslation()
  const { formState, trigger } = useFormContext<BookingCreateForm>()
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = async () => {
    const fields = steps[activeStep].fields
    const isValid = await trigger(fields as FieldPath<BookingCreateForm>[])
    if (isValid) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const isStepFailed = (fields: string[]) => {
    return fields.some(field => Object.keys(formState.errors).includes(field))
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
        {steps.map(step => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: { error?: boolean } = {}

          if (isStepFailed(step.fields)) {
            labelProps.error = true
          }

          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{t(step.label)}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <Fragment>
        <Box>{steps[activeStep].component}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 3 }}>
          <Button color='primary' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} disableElevation>
            {t('Back')}
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep !== steps.length - 1 && (
            <Button variant='contained' onClick={handleNext} disableElevation>
              {t('Next')}
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <LoadingButton type='submit' variant='contained' disableElevation loading={loading}>
              {t('Confirm')}
            </LoadingButton>
          )}
        </Box>
      </Fragment>
    </Box>
  )
}
