import { useEffect, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, Grid2 as Grid, Link, SvgIcon, TextField, Typography, useMediaQuery } from '@mui/material'

// third-party
import { Trans, useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

// project imports
import useAuth from '../../../context/auth/useAuth'
import Copyright from '../../Landing/Copyright/Copyright'
import ResponsiveCard from '../../../ui-components/extended/card/ResponsiveCard'
import LogoSection from '../../../layout/LogoSection/LogoSection'

// assets
import { ReactComponent as GoogleIcon } from '../../../assets/images/google.svg'
import { FirebaseError } from '../../../types/ApiError'

// ========================|| SIGNUP ||======================== //

interface SignupForm {
  email: string
}

export default function SignIn() {
  // context
  const { t } = useTranslation()
  const { error, loading, signInWithGoogle, signInWithLink, sendSignInLink } = useAuth()

  // hooks
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  // react-hook-form
  const { handleSubmit, control } = useForm<SignupForm>()

  // state
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [agreeWithTerms, setAgreeWithTerms] = useState(true)

  useEffect(() => signInWithLink(), [])

  /**
   * Delegates the login to the authentication provider.
   */
  const handleSubmitForm: SubmitHandler<SignupForm> = signupForm => {
    sendSignInLink(signupForm.email, window.location.href).then(() => setIsEmailSent(true))
  }

  /**
   * Get firebase error messages.
   *
   * @param error the firebase error.
   */
  const getErrorMessage = (error: FirebaseError): string => {
    if (error.code === 'auth/account-exists-with-different-credential') {
      return 'This account is already linked to another social network.'
    }

    return 'Some error has occurred with your request, please try again.'
  }

  return (
    <Box minHeight='100vh' sx={{ backgroundColor: theme.palette.grey[100] }}>
      <Grid container display='flex' flexDirection='column' flexWrap='wrap' justifyContent='flex-end' minHeight='100vh'>
        <Grid maxWidth='none' size={12}>
          <Grid
            container
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            justifyContent='center'
            alignItems='center'
            minHeight='calc(100vh - 68px)'
          >
            <Grid
              sx={{
                [theme.breakpoints.up('xs')]: { margin: '8px' },
                [theme.breakpoints.up('sm')]: { margin: '24px' },
              }}>
              <ResponsiveCard>
                <Grid container spacing={2} justifyContent='center' alignItems='center'>
                  <Grid mb='24px'>
                    <LogoSection />
                  </Grid>
                  <Grid size={12}>
                    <Grid container display='flex' justifyContent='center' alignItems='center'>
                      <Grid size={12}>
                        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                          <Typography variant='h2' color='secondary' fontSize={matchesSM ? '1.25rem' : '1.5rem'} gutterBottom>
                            {t('Sign In')}
                          </Typography>
                          <Typography component='span' variant='caption' fontSize='16px' mt='8px' textAlign='center'>
                            {t('Sign in using social media to get quick access')}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {error && (
                    <Grid size={12}>
                      <Alert severity='error' sx={{ display: 'flex', alignItems: 'center' }}>
                        {t(getErrorMessage(error))}
                      </Alert>
                    </Grid>
                  )}
                  {isEmailSent && (
                    <Grid size={12}>
                      <Alert severity='success' sx={{ display: 'flex', alignItems: 'center' }}>
                        {t('Check your email, we have sent a link to continue the sign in.')}
                      </Alert>
                    </Grid>
                  )}
                  <Grid size={12}>
                    <Grid container spacing={2} display='flex' flexDirection='column' flexWrap='wrap' justifyContent='center'>
                      <Grid size={12}>
                        <LoadingButton
                          variant='outlined'
                          size='large'
                          fullWidth
                          disabled={!agreeWithTerms}
                          startIcon={loading ? null : <SvgIcon component={GoogleIcon} inheritViewBox />}
                          onClick={signInWithGoogle}
                          sx={{
                            color: theme.palette.grey[700],
                            backgroundColor: theme.palette.grey[50],
                            border: `1px solid ${theme.palette.grey[100]}`,
                            borderRadius: '4px',
                          }}
                        >
                          {t('Sign in with Google')}
                        </LoadingButton>
                      </Grid>
                      <Grid size={12}>
                        <Box display='flex' alignItems='center'>
                          <Divider sx={{ flexGrow: 1, borderColor: theme.palette.grey[100] }} />
                          <Button
                            variant='outlined'
                            disabled
                            sx={{
                              margin: '16px',
                              padding: '4px 56px',
                              fontWeight: 500,
                              borderColor: `${theme.palette.grey[100]} !important`,
                              borderRadius: `${theme.shape.borderRadius}px`,
                              color: `${theme.palette.grey[900]} !important`,
                            }}
                          >
                            {t('OR')}
                          </Button>
                          <Divider sx={{ flexGrow: 1, borderColor: theme.palette.grey[100] }} />
                        </Box>
                      </Grid>
                      <Grid display='flex' justifyContent='center' alignItems='center' size={12}>
                        <Box mb='16px'>
                          <Typography variant='subtitle1'>{t('Sign in with an email link')}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                      <Controller
                        name='email'
                        defaultValue=''
                        control={control}
                        rules={{
                          required: 'This field is required.',
                          pattern: {
                            value: /^[^@]+@[^@]+$/,
                            message: 'Must be a valid email.',
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            id='email'
                            placeholder={t('Email') as string}
                            fullWidth
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? t(error.message as string) : null}
                            sx={{ marginY: '8px' }}
                            slotProps={{
                              htmlInput: { autoComplete: 'on' }
                            }}
                          />
                        )}
                      />
                      <Box alignItems='center' mb='16px'>
                        <FormControlLabel
                          control={<Checkbox id='privacy-policy' checked={agreeWithTerms} onChange={() => setAgreeWithTerms(!agreeWithTerms)} />}
                          label={
                            <Typography variant='subtitle1'>
                              <Trans t={t} i18nKey='terms'>
                                Agree with
                                <Link component='a' color='inherit' href={'/terms'}>
                                  Terms & Condition
                                </Link>
                                .
                              </Trans>
                            </Typography>
                          }
                          componentsProps={{ typography: { color: theme.palette.grey[700] } }}
                        />
                      </Box>
                      <Box>
                        <LoadingButton
                          type='submit'
                          variant='contained'
                          size='large'
                          color='secondary'
                          loading={loading}
                          disabled={!agreeWithTerms}
                          disableElevation
                          fullWidth
                        >
                          {t('Send secure link')}
                        </LoadingButton>
                      </Box>
                    </form>
                  </Grid>
                </Grid>
              </ResponsiveCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid margin='8px 24px 24px' size={12}>
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
