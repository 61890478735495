import { useContext } from 'react'

// material-ui
import { Grid2 as Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import CompaniesContext from '../../../../../context/company/CompaniesContext'
import CompanySearchBar from '../../CompanySearchBar/CompanySearchBar'

// ========================|| COMPANY - CARD HEADER ||======================== //

function CompaniesCounter() {
  const { companies } = useContext(CompaniesContext)

  if (companies) {
    return (
      <Typography component='span' variant='h3' fontWeight={500} fontSize='1.125rem' color='grey.300'>
        &nbsp;({companies.page.totalElements})
      </Typography>
    )
  }

  return null
}

export default function CompanyCardHeader() {
  // hooks
  const { t } = useTranslation()

  return (
    <Grid container spacing={3} display='flex' justifyContent='space-between' alignItems='center'>
      <Grid>
        <Typography variant='h3' fontWeight={500} fontSize='1.125rem'>
          {t('Companies')}
          <CompaniesCounter />
        </Typography>
      </Grid>
      <Grid>
        <CompanySearchBar />
      </Grid>
    </Grid>
  )
}
