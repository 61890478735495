import React, { useContext, useState } from 'react'

// material-ui
import { Box, Divider, Grid2 as Grid, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import BusinessHoursContext from '../../../../context/businesshour/BusinessHoursContext'
import CompanyBusinessHoursApi from '../../../../api/businesshour/CompanyBusinessHoursApi'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import BusinessHoursFormControl from '../BusinessHoursFormControl/BusinessHoursFormControl'
import { BusinessHour } from '../../../../types/BusinessHour'
import { setSnackbar } from '../../../../store/snackbar/reducer'

// apis
const businessHoursApi = new CompanyBusinessHoursApi()

// ========================|| BUSINESS HOURS - FORM ||======================== //

interface FormValues {
  businessHours: BusinessHour[]
}

export default function BusinessHoursForm() {
  // hooks
  const theme = useTheme()
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { businessHours, setBusinessHours } = useContext(BusinessHoursContext)
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      businessHours: businessHours,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (businessHours: BusinessHour[]) => {
    setBusinessHours(businessHours)

    methods.reset({ businessHours })

    dispatch(
      setSnackbar({
        message: t('Business hours updated successfully') as string,
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating business hours'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      businessHoursApi
        .update(token, companyId, form)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          setLoading(false)
        })
    })
  }

  return (
    <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} sx={{ [theme.breakpoints.only('xs')]: { width: 'max-content' } }}>
      <Grid container spacing={3}>
        <Grid size={12}>
          <FormProvider {...methods}>
            <BusinessHoursFormControl />
          </FormProvider>
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <LoadingButton variant='contained' type='submit' disabled={!methods.formState.isDirty} loading={loading}>
            {t('Save changes')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
