import React, { useContext } from 'react'

// material-ui
import { Grid2 as Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserAssistantsContext from '../../../../context/userassistant/UserAssistantsContext'
import MainTablePagination from '../../../../ui-components/extended/table/MainTablePagination'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import AssistantUserRoleChip from '../../../CompanyLayout/Assistant/AssistantUserRoleChip/AssistantUserRoleChip'
import UserAssistantSeeButton from '../UserAssistantSeeButton/UserAssistantSeeButton'
import UserAssistantDeleteButton from '../UserAssistantDeleteButton/UserAssistantDeleteButton'
import CompanyAvatar from '../../../../ui-components/extended/avatar/CompanyAvatar'

// ========================|| USER ASSISTANT - TABLE ||======================== //

export default function UserAssistantTable() {
  // hooks
  const { t } = useTranslation()
  const { userAssistants, params, setParams } = useContext(UserAssistantsContext)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Company')}</TableCell>
              <TableCell>{t('Assistant')}</TableCell>
              <TableCell align='center'>{t('Role')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userAssistants.content.map(userAssistant => (
              <TableRow key={userAssistant.id}>
                <TableCell>
                  <Grid container spacing={2} display='flex' alignItems='center' sx={{ flexFlow: 'nowrap' }}>
                    <Grid>
                      <CompanyAvatar active={userAssistant.company.active} />
                    </Grid>
                    <Grid>
                      <Typography variant='subtitle1' align='left' whiteSpace='nowrap'>
                        {userAssistant.company.displayName}
                      </Typography>
                      <Typography variant='caption' align='left' whiteSpace='nowrap'>
                        @{userAssistant.company.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>{userAssistant.name}</TableCell>
                <TableCell align='center'>
                  <AssistantUserRoleChip role={userAssistant.role} />
                </TableCell>
                <TableCell align='center'>
                  <UserAssistantSeeButton userAssistant={userAssistant} />
                  <UserAssistantDeleteButton userAssistant={userAssistant} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <MainTablePagination count={userAssistants.page.totalElements} pageable={params} setPageable={setParams} />
    </>
  )
}
