import React, { useContext } from 'react'

// material-ui
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid2 as Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import IntegrationContext from '../../../../context/integration/IntegrationContext'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useAssistants from '../../../../hooks/assistant/useAssistants'
import ServiceFormAdvanced from './ServiceFormAdvanced/ServiceFormAdvanced'
import IntegrationMercadoPagoNotEnabledAlert from '../../../../ui-components/extended/alert/IntegrationMercadoPagoNotEnabledAlert'
import DurationHelperText from './DurationHelperText/DurationHelperText'
import { PriceType, ServiveForm } from '../../../../types/Service'

// ========================|| SERVICE - FORM ||======================== //

export default function ServiceForm() {
  // hooks
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { integration } = useContext(IntegrationContext)
  const { loading, assistants } = useAssistants(companyId)
  const { control, watch, setValue } = useFormContext<ServiveForm>()

  // state
  const priceType: PriceType = watch('priceType')
  const customDepositPercentage: boolean = watch('customDepositPercentage')
  const depositPercentage: number = Number(watch('depositPercentage'))

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid size={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Name is too short.' },
            maxLength: { value: 100, message: 'Name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id='name'
              label={t('Name')}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? t(error.message as string) : null}
              fullWidth
              slotProps={{
                input: {
                  autoComplete: 'off',
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='description'
          control={control}
          rules={{
            minLength: { value: 2, message: 'Description is too short.' },
            maxLength: { value: 255, message: 'Description is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id='description'
              label={t('Description')}
              multiline
              rows={4}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? t(error.message as string) : null}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='priceType'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { onChange, value } }) => (
            <FormControl component='fieldset' variant='standard' fullWidth onChange={onChange}>
              <FormLabel id='price-type-group-label' component='legend'>
                <Typography variant='subtitle1'>{t('Price type')}</Typography>
              </FormLabel>
              <RadioGroup row aria-labelledby='price-type-group-label' name='price-type-group' value={value}>
                <FormControlLabel value={PriceType.FIXED} control={<Radio />} label={t('Fixed')} />
                <FormControlLabel value={PriceType.TBD} control={<Radio />} label={t('To be defined')} />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
      {PriceType.FIXED === priceType && (
        <>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='price'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 0, message: 'Price must be greater than or equal to zero.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id='price'
                  label={t('Price')}
                  value={value}
                  onChange={e => {
                    const inputValue = e.target.value
                    // Removes any character that is not a number
                    const numericValue = inputValue.replace(/[^0-9]/g, '')

                    // Allow only integers up to 6 digits
                    if (/^\d{0,6}$/.test(numericValue)) {
                      onChange(numericValue)
                    }
                  }}
                  error={!!error}
                  fullWidth
                  helperText={error ? t(error.message as string) : null}
                  slotProps={{
                    input: { startAdornment: <InputAdornment position='start'>$</InputAdornment> },
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='currency'
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel id='currency-select-label' htmlFor='currency-select'>
                    {t('Currency')}
                  </InputLabel>
                  <Select
                    labelId='currency-select-label'
                    label={t('Currency')}
                    value={value}
                    onChange={onChange}
                    inputProps={{ id: 'currency-select' }}
                  >
                    <MenuItem value='ARS'>{t('(ARS) Argentine Peso')}</MenuItem>
                  </Select>
                  {error && <FormHelperText error>{t(error.message as string)}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name='customDepositPercentage'
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl component='fieldset' variant='standard' fullWidth onChange={onChange}>
                  <FormControlLabel
                    control={<Switch id='customDepositPercentage' value={value} checked={value} />}
                    label={t('Custom deposit percentage')}
                  />
                  <FormHelperText>
                    {t('Activate it to use a custom deposit percentage or disable it to use the deposit percentage defined in the company settings.')}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {customDepositPercentage && (
            <>
              {!integration.mercadoPago && depositPercentage > 0 && (
                <Grid size={12}>
                  <IntegrationMercadoPagoNotEnabledAlert />
                </Grid>
              )}
              <Grid size={12}>
                <Controller
                  name='depositPercentage'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl component='fieldset' variant='standard' fullWidth>
                      <FormLabel component='legend'>
                        <Typography variant='subtitle1' gutterBottom>
                          {t('Deposit percentage')}
                        </Typography>
                      </FormLabel>
                      <Grid container spacing={1} display='flex' alignItems='center'>
                        <Grid>
                          <Typography mr={2}>0%</Typography>
                        </Grid>
                        <Grid display='flex' alignItems='center' size='grow'>
                          <Slider
                            aria-label='Deposit Percentage'
                            value={Number(value)}
                            onChange={(event, value) => onChange(value.toString())}
                            getAriaValueText={value => `${value * 100}%`}
                            valueLabelDisplay='auto'
                            shiftStep={5}
                            step={5}
                            min={0}
                            max={100}
                          />
                        </Grid>
                        <Grid>
                          <Typography ml={2}>100%</Typography>
                        </Grid>
                      </Grid>
                      <FormHelperText>
                        {t('Determines the percentage of the service price that the client must pay when making a booking.')}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            </>
          )}
        </>
      )}
      <Grid size={12}>
        <Controller
          name='duration'
          control={control}
          rules={{
            required: 'This field is required.',
            min: { value: 15, message: 'Duration must be greater than or equal to 15 minutes.' },
            max: { value: 720, message: 'Duration must be less than or equal to 12 hours.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error}>
              <InputLabel htmlFor='duration'>{t('Duration')}</InputLabel>
              <OutlinedInput
                id='duration'
                label={t('Duration')}
                value={value}
                onChange={e => {
                  const duration = e.target.value
                  const inputValue = duration.replace(/[^0-9]/g, '')
                  const totalDuration = parseInt(duration) + parseInt(watch('buffer'))
                  setValue('frequency', totalDuration.toString(), { shouldDirty: true })
                  onChange(inputValue)
                }}
                inputProps={{ type: 'number' }}
              />
              <DurationHelperText helperText={error ? t(error.message as string) : t('duration_helperText')} duration={parseInt(value)} />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='assistants'
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth>
              <Autocomplete
                id='assistants'
                options={assistants}
                value={value || null}
                loading={loading}
                multiple
                limitTags={5}
                disableCloseOnSelect
                onChange={(e, assistant) => onChange(assistant)}
                isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                getOptionLabel={assistant => assistant.name}
                renderOption={(props, assistant, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      id={assistant.name}
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <Box display='flex' flexDirection='column'>
                      <Typography variant='subtitle1'>{assistant.name}</Typography>
                      <Typography variant='caption'>{assistant.branch.name}</Typography>
                    </Box>
                  </li>
                )}
                renderInput={params => <TextField {...params} label={t('Assistants')} />}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <ServiceFormAdvanced />
      </Grid>
    </Grid>
  )
}
