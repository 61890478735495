// material-ui
import { useTheme } from '@mui/material/styles'
import { Button, CardContent, Grid2 as Grid, Stack, Typography, useMediaQuery } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// project imports
import { setOpened } from '../../../../store/menu/reducer'
import { YellowCard } from '../../../../ui-components/extended/card/CardStyles'

// ==============================|| SIDEBAR MENU - CREATE COMPANY CARD ||============================== //

const CreateCompanyCard = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const handleNavigate = () => {
    if (matchDownMd) {
      dispatch(setOpened(false))
    }
    navigate('/users/me/companies')
  }

  return (
    <YellowCard variant='outlined'>
      <CardContent>
        <Grid container direction='column' spacing={2}>
          <Grid>
            <Typography variant='h4'>{t('Register your company')}</Typography>
          </Grid>
          <Grid>
            <Typography variant='subtitle2' color='text.dark' sx={{ opacity: 0.6 }}>
              {t('Get a 30-day free trial.')}
            </Typography>
          </Grid>
          <Grid>
            <Stack direction='row'>
              <Button variant='contained' color='warning' sx={{ boxShadow: 'none' }} onClick={handleNavigate}>
                {t('Start now')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </YellowCard>
  )
}

export default CreateCompanyCard
