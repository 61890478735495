import { Plan } from './Plan'

export enum SubscriptionStatus {
  FREE_TRIAL = 'FREE_TRIAL',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PAUSED = 'PAUSED',
}

export interface Subscription {
  id: string
  price: number
  status: SubscriptionStatus
  freeTrialEndDate: Date | null
  nextPaymentDate: Date
  externalReference: string
  assistants: number
  branches: number
  services: number
  clients: number
  plan: Plan
}
