import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid2 as Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import AssistantsContext from '../../../../context/assistant/AssistantsContext'
import AssistantApi from '../../../../api/assistant/CompanyAssistantApi'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import AssistantForm from '../AssistantForm/AssistantForm'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Assistant, AssistantCreateForm } from '../../../../types/Assistant'
import { ApiError } from '../../../../types/ApiError'

// constants
import {
  EXCEPTION_ASSISTANT_LIMIT_REACHED,
  EXCEPTION_ASSISTANT_NAME_ALREADY_EXIST,
  EXCEPTION_SUBSCRIPTION_REQUIRED,
} from '../../../../api/exceptions/exceptions'
import CompanyStatsContext from '../../../../context/companystats/CompanyStatsContext'

// apis
const assistantApi = new AssistantApi()

// ========================|| ASSISTANT - CREATE DIALOG ||======================== //

interface Props extends DialogProps {
  onClose: () => void
}

export default function AssistantCreateDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(CompanyStatsContext)
  const { setAssistants } = useContext(AssistantsContext)
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<AssistantCreateForm>({
    defaultValues: {
      name: '',
      branch: undefined,
      services: [],
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (assistant: Assistant) => {
    setAssistants(assistants => assistants.concat(assistant))
    setStats({ ...stats, assistants: stats.assistants + 1 })

    dispatch(
      setSnackbar({
        message: t('Assistant created successfully'),
        severity: 'success',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_ASSISTANT_NAME_ALREADY_EXIST) {
      methods.setError('name', { message: EXCEPTION_ASSISTANT_NAME_ALREADY_EXIST })
      return
    }

    if (error.message === EXCEPTION_ASSISTANT_LIMIT_REACHED) {
      dispatch(setSnackbar({ message: t(EXCEPTION_ASSISTANT_LIMIT_REACHED), severity: 'error', open: true }))
    }

    else if (error.message === EXCEPTION_SUBSCRIPTION_REQUIRED) {
      dispatch(setSnackbar({ message: t(EXCEPTION_SUBSCRIPTION_REQUIRED), severity: 'error', open: true }))
    }

    else {
      dispatch(setSnackbar({ message: t('An unexpected error occurred while creating assistant'), severity: 'error', open: true }))
    }

    onClose()
    methods.reset()
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<AssistantCreateForm> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      assistantApi
        .create(token, companyId, {
          name: form.name,
          branchId: form.branch.id,
          serviceIds: form.services.map(services => services.id),
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Add assistant')}</DialogTitle>
          <DialogContent>
            <AssistantForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid size={12} container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <LoadingButton type='submit' variant='contained' size='large' loading={loading} fullWidth disableElevation>
                  {t('Create')}
                </LoadingButton>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
