// material-ui
import { useTheme } from '@mui/material/styles'
import { Card, Grid2 as Grid, Typography, useMediaQuery } from '@mui/material'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

// third-party
import { Link } from 'react-router-dom'
import { Icon, IconChevronRight } from '@tabler/icons-react'

// project imports
import Helper from './Helper'
import { Breadcrumb } from '../../../types/Breadcrumb'

// ==============================|| UI COMPONENTS - BREADCRUMBS ||============================== //

const linkSX = {
  display: 'flex',
  textDecoration: 'none',
  alignContent: 'center',
  alignItems: 'center',
  color: 'grey.900',
}

const lastSX = {
  display: 'flex',
  textDecoration: 'none',
  alignContent: 'center',
  alignItems: 'center',
  color: 'grey.500',
}

interface Props {
  card?: boolean
  titleBottom?: boolean
  rightAlign?: boolean
  separator?: Icon
  tooltip?: string
  items: Breadcrumb[]
}

export default function Breadcrumbs({ card = true, titleBottom = false, rightAlign = false, separator, tooltip, items }: Props) {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  const SeparatorIcon = separator as Icon
  const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size='1rem' /> : <IconChevronRight stroke={1.5} size='1rem' />

  return (
    <Card
      sx={{
        border: 'none',
        background: card ? theme.palette.background.default : 'transparent',
        boxShadow: 'none',
      }}
    >
      <Grid
        container
        direction={rightAlign ? 'row' : 'column'}
        justifyContent={rightAlign ? 'space-between' : 'flex-start'}
        alignItems={rightAlign ? 'center' : 'flex-start'}
        spacing={1}
      >
        {!titleBottom && (
          <Grid>
            <Typography variant='h3' fontWeight={500} display='flex' alignItems='center'>
              {items[items.length - 1].title}
              {tooltip && <Helper title={tooltip} />}
            </Typography>
          </Grid>
        )}
        <Grid>
          <MuiBreadcrumbs
            sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
            aria-label='breadcrumb'
            maxItems={matchesXS ? 3 : 8}
            separator={separatorIcon}
          >
            {items.map((item, index) => {
              const last = index === items.length - 1

              if (last || !item.url) {
                return (
                  <Typography variant='subtitle1' key={item.title} sx={lastSX}>
                    {item.title}
                  </Typography>
                )
              }

              return (
                <Typography variant='subtitle1' key={item.title} component={Link} to={item.url} sx={linkSX}>
                  {item.title}
                </Typography>
              )
            })}
          </MuiBreadcrumbs>
        </Grid>
        {titleBottom && (
          <Grid>
            <Typography variant='h3' fontWeight={500} display='flex' alignItems='center'>
              {items[items.length - 1].title}
              {tooltip && <Helper title={tooltip} />}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Card>
  )
}
