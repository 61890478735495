import React, { useContext, useState } from 'react'

// material-ui
import { Box, Divider, FormControl, FormHelperText, Grid2 as Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { useDispatch } from 'react-redux'

// project imports
import UserContext from '../../../../context/user/UserContext'
import UserApi from '../../../../api/user/UserApi'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { User } from '../../../../types/User'

// apis
const userApi = new UserApi()

// ========================|| ACCOUNT SETTINGS ||======================== //

interface FormValues {
  name: string
  phone: string
  locale: string
}

export default function AccountSettingsCard() {
  // hooks
  const { t, i18n } = useTranslation()
  const { auth } = useAuth()
  const { user, setUser } = useContext(UserContext)

  const dispatch = useDispatch()

  // react-hook-form
  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      name: user.name,
      phone: user.phone,
      locale: user.locale,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (user: User) => {
    setUser(user)
    i18n.changeLanguage(user.locale)

    reset({
      name: user.name,
      phone: user.phone,
      locale: user.locale,
    })

    dispatch(
      setSnackbar({
        message: t('Profile updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating profile'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    setLoading(false)
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      userApi.update(token, form).then(handleSuccess).catch(handleError).finally(handleCloseForm)
    })
  }

  return (
    <SubCard title={t('Profile')}>
      <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 4 }}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'This field is required.',
                minLength: { value: 2, message: 'Your full name is too short.' },
                maxLength: { value: 100, message: 'Your full name is too long.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id='name'
                  label={t('Full name')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message as string) : null}
                  slotProps={{
                    input: {
                      autoComplete: 'off'
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Controller
              name='phone'
              defaultValue={auth?.phoneNumber || ''}
              control={control}
              rules={{ validate: matchIsValidTel }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <MuiTelInput
                  id='phone'
                  label={t('Phone number')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t('Invalid phone number.') : null}
                  fullWidth
                  forceCallingCode
                  continents={['SA']}
                  defaultCountry='AR'
                  preferredCountries={['AR']}
                  slotProps={{
                    input: {
                      autoComplete: 'off'
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Controller
              name='locale'
              defaultValue={user.locale || ''}
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth>
                  <InputLabel id='locale-select-label' htmlFor='locale-select'>
                    {t('Language')}
                  </InputLabel>
                  <Select
                    labelId='locale-select-label'
                    value={value}
                    label={t('Language')}
                    onChange={onChange}
                    inputProps={{ id: 'locale-select' }}
                  >
                    <MenuItem value='es-AR'>
                      <Grid container>
                        <Typography>{t('Spanish')}</Typography>
                        <Typography variant='caption' color='text.secondary' sx={{ ml: '4px' }}>
                          (AR)
                        </Typography>
                      </Grid>
                    </MenuItem>
                    <MenuItem value='en-US'>
                      <Grid container>
                        <Typography>{t('English')}</Typography>
                        <Typography variant='caption' color='text.secondary' sx={{ ml: '4px' }}>
                          (US)
                        </Typography>
                      </Grid>
                    </MenuItem>
                  </Select>
                  {error && <FormHelperText>{t(error.message as string)}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <LoadingButton type='submit' variant='contained' disabled={!formState.isDirty} loading={loading}>
              {t('Save changes')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </SubCard>
  )
}
