// material-ui
import { IconButton, Tooltip } from '@mui/material'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// project imports
import { UserBranch } from '../../../../types/UserBranch'

// ========================|| USER BRANCH - SEE BUTTON ||======================== //

interface Props {
  userBranch: UserBranch
}

export default function UserBranchSeeButton({ userBranch }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/branches/${userBranch.id}/bookings`)
  }

  return (
    <Tooltip title={t('See branch')}>
      <IconButton aria-label='See branch' color='primary' size='large' onClick={handleNavigate}>
        <VisibilityTwoToneIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}
