import { useState } from 'react'

// material-ui
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import WhatsAppDialog from '../BookingWhatsAppDialog/WhatsAppDialog'
import { Booking } from '../../../../types/Booking'

// ==============================|| BUTTONS - WHATSAPP ||============================== //

interface Props extends IconButtonProps {
  booking: Booking
}

export default function WhatsAppIconButton({ booking, ...props }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Chat on WhatsApp')}>
        <span>
          <IconButton {...props} size='large' onClick={handleOpen} sx={{ color: '#25d366' }}>
            <WhatsAppIcon fontSize='small' color='inherit' />
          </IconButton>
        </span>
      </Tooltip>
      <WhatsAppDialog booking={booking} onClose={handleClose} open={open} />
    </>
  )
}
