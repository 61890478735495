import { useEffect, useState } from 'react'

// project imports
import AvailabilityApi from '../../api/availability/AvailabilityApi'
import useAuth from '../../context/auth/useAuth'
import { ApiError } from '../../types/ApiError'
import { Dayjs } from 'dayjs'
import { TimeSlot } from '../../types/TimeSlot'

// apis
const availabilityApi = new AvailabilityApi()

// ========================|| HOOK - TIMES ||======================== //

const useTimes = (serviceId?: string, assistantId?: string, date?: Dayjs | null) => {
  const { auth } = useAuth()
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()

  const searchDateSet = () => {
    if (serviceId && assistantId && date) {
      setLoading(true)
      auth!.getIdToken().then(token => {
        availabilityApi.getTimeSet(token, serviceId, assistantId, date.format('YYYY-MM-DD'))
          .then(data => setTimeSlots(data.times))
          .catch(error => setError(error))
          .finally(() => setLoading(false))
      })
    }
  }

  useEffect(searchDateSet, [serviceId, assistantId, date])

  return { loading, error, timeSlots }
}

export default useTimes