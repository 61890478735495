// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Card, CardContent, Grid2 as Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// ========================|| FEATURE ||======================== //

interface Props {
  icon: any
  title: string
  description: string
  color?: string
  backgroundColor?: string
}

export default function FeatureCard({ icon, title, description, color, backgroundColor }: Props) {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Card
      variant='outlined'
      sx={{
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: `${theme.shape.borderRadius}px`,
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid display='flex' justifyContent='center' size={12}>
            <Avatar
              sx={{
                width: 82,
                height: 82,
                color: color || theme.palette.primary.main,
                backgroundColor: backgroundColor || theme.palette.primary.light,
              }}
              variant='rounded'
            >
              {icon}
            </Avatar>
          </Grid>
          <Grid size={12}>
            <Typography variant='h3' textAlign='center'>
              {t(title)}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography component='p' variant='body2' textAlign='center' color='text.primary'>
              {t(description)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
