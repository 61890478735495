import * as React from 'react'

// material-ui
import { Autocomplete, AutocompleteProps, Box, Grid2 as Grid, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import useBranches from '../../../../hooks/branch/useBranches'
import { Branch } from '../../../../types/Branch'

// ========================|| UI COMPONENTS - AUTOCOMPLETE - BRANCH ||======================== //

interface Props extends Omit<AutocompleteProps<Branch, false, false, false>, 'options' | 'loading' | 'getOptionKey' | 'isOptionEqualToValue'> {
  companyId: string
}

export default function BranchAutocomplete({ companyId, ...props }: Props) {
  const { t } = useTranslation()
  const { loading, branches } = useBranches(companyId)

  return (
    <Autocomplete
      {...props}
      fullWidth
      options={branches}
      loading={loading}
      getOptionKey={option => option.id}
      isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
      noOptionsText={t('No options')}
      getOptionLabel={option => option.name}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Box component='span' sx={{ fontWeight: 'bold' }}>
                  {option.name}
                </Box>
                <Typography variant='body2' color='text.secondary'>
                  {option.address.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}
