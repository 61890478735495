import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import AssistantDeleteDialog from '../AssistantDeleteDialog/AssistantDeleteDialog'
import { Assistant } from '../../../../types/Assistant'

// ========================|| ASSISTANT - DELETE BUTTON ||======================== //

interface Props {
  assistant: Assistant
}

export default function AssistantDeleteIconButton({ assistant }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Delete assistant')}>
        <IconButton color='error' size='large' onClick={handleOpen}>
          <DeleteIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <AssistantDeleteDialog open={open} assistant={assistant} onClose={handleClose} />
    </>
  )
}
