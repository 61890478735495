// material-ui
import { FormControl, useMediaQuery, useTheme } from '@mui/material'
import { DatePickerProps, DayCalendarSkeleton, MobileDatePicker } from '@mui/x-date-pickers'

// third-party
import { useTranslation } from 'react-i18next'
import { Dayjs } from 'dayjs'
import { ServiceDetails } from '../../../types/Service'

// project imports
import useDates from '../../../hooks/availability/useDates'
import { ServiceAssistant } from '../../../types/ServiceAssistant'

// ========================|| UI COMPONENTS - DATE PICKER - RESPONSIVE ||======================== //

interface Props extends DatePickerProps<Dayjs> {
  service: ServiceDetails | null
  assistant: ServiceAssistant | null
}

export default function ServiceAssistantDatePicker({ service, assistant, ...props }: Props) {
  // hooks
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { t } = useTranslation()

  const { loading, dates } = useDates(service?.id, assistant?.id)

  const shouldDisableDate = (dayjs: Dayjs) => {
    return !dates.find(date => dayjs.isSame(date, 'date'))
  }

  return (
    <FormControl fullWidth>
      <MobileDatePicker
        {...props}
        views={['day']}
        format='dddd D, MMMM'
        label={matchesXS ? null : t('Date')}
        loading={loading}
        renderLoading={() => <DayCalendarSkeleton />}
        shouldDisableDate={shouldDisableDate}
        disablePast
        closeOnSelect
        disableHighlightToday
        slotProps={{
          toolbar: { hidden: true },
          textField: {
            ...props.slotProps?.textField,
            placeholder: matchesXS ? t('Date') : '',
            inputProps: {
              style: {
                textTransform: 'capitalize',
              },
            },
          },
        }}
      />
    </FormControl>
  )
}