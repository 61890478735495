import { useContext } from 'react'

// material-ui
import { Box, Grid2 as Grid, Typography } from '@mui/material'

// project imports
import SubscriptionContext from '../../../../context/subscription/SubscriptionContext'
import PaymentMethodForm from '../PaymentMethodForm/PaymentMethodForm'
import PaymentMethodCancelButton from '../PaymentMethodCancelButton/PaymentMethodCancelButton'
import PaymentMethodEditButton from '../PaymentMethodEditButton/PaymentMethodEditButton'
import { SubscriptionStatus } from '../../../../types/Subscription'

// ========================|| PAYMENT - UPDATE FORM ||======================== //

export default function PaymentMethodUpdateForm() {
  // hooks
  const { subscription } = useContext(SubscriptionContext)

  const isPaused = subscription.status === SubscriptionStatus.PAUSED

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Grid container spacing={1} display='flex' justifyContent='space-between' alignItems='center'>
          <Grid>
            <PaymentMethodForm />
          </Grid>
          {isPaused ? (
            <Grid>
              <PaymentMethodCancelButton />
            </Grid>
          ) : (
            <Grid>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <PaymentMethodCancelButton />
                <Typography variant='caption' mx='8px' color='#e0e0e0'>|</Typography>
                <PaymentMethodEditButton />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
