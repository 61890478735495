import { useContext, useState } from 'react'

// third-party
import { useTranslation } from 'react-i18next'

// material-ui
import { Fab, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/AddTwoTone'

// project imports
import CompanyStatsContext from '../../../../context/companystats/CompanyStatsContext'
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import ClientCreateDialog from '../ClientCreateDialog/ClientCreateDialog'
import { isInactive } from '../../../../utils/subscription'

// ========================|| CLIENT - CREATE BUTTON ||======================== //

export default function ClientCreateButton() {
  // hooks
  const { t } = useTranslation()
  const { stats } = useContext(CompanyStatsContext)
  const { subscription } = useContext(OptionalSubscriptionContext)

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const tooltipTitle = () => {
    if (isInactive(subscription)) {
      return 'An active subscription is required to create clients.'
    }

    if (subscription && subscription.clients === stats.clients) {
      return 'Clients limit reached. If you need more clients, please contact us.'
    }

    return 'Create client'
  }

  const isDisabled = isInactive(subscription) || (subscription && subscription.clients === stats.clients)

  return (
    <>
      <Tooltip title={t(tooltipTitle())}>
        <span>
          <Fab
            color='primary'
            aria-label='Create Client'
            sx={{
              width: '32px',
              height: '32px',
              minHeight: '32px',
              boxShadow: 'none',
            }}
            disabled={isDisabled}
            onClick={handleOpen}
          >
            <AddIcon fontSize='small' />
          </Fab>
        </span>
      </Tooltip>
      <ClientCreateDialog open={open} onClose={handleClose} />
    </>
  )
}
