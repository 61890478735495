import React, { useContext, useEffect, useMemo, useState } from 'react'

// material-ui
import { Autocomplete, Box, Divider, FormControl, Grid2 as Grid, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import LocationOnIcon from '@mui/icons-material/LocationOn'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import parse from 'autosuggest-highlight/parse'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'

// project imports
import UserCompanyContext from '../../../../context/usercompany/UserCompanyContext'
import BranchContext from '../../../../context/branch/BranchContext'
import BranchApi from '../../../../api/branch/BranchApi'
import useAuth from '../../../../context/auth/useAuth'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import { defaultKey, key } from '../../../../utils/keyGenerator'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Branch } from '../../../../types/Branch'
import { ApiError } from '../../../../types/ApiError'
import { Place } from '../../../../types/Place'
import { EXCEPTION_BRANCH_ADDRESS_ALREADY_EXIST, EXCEPTION_BRANCH_NAME_ALREADY_EXIST } from '../../../../api/exceptions/exceptions'

// apis
const branchApi = new BranchApi()

// ========================|| TAB - BRANCH ||======================== //

interface FormValues {
  name: string
  place: Place | null
  latitude: string
  longitude: string
}

export default function BranchTab() {
  // hooks
  const { auth } = useAuth()
  const { i18n, t } = useTranslation()
  const { userCompany } = useContext(UserCompanyContext)
  const { branch, setBranch } = useContext(BranchContext)
  const dispatch = useDispatch()

  const defaultValue = useMemo(
    () => ({
      key: defaultKey(),
      description: branch.address.description,
      structured_formatting: {
        main_text: branch.address.primary,
        secondary_text: branch.address.secondary,
        main_text_matched_substrings: [{ offset: 0, length: branch.address.primary.length }],
      },
    }),
    [branch],
  )

  // react-hook-form
  const { control, watch, reset, handleSubmit, formState, setValue, setError, clearErrors } = useForm<FormValues>({
    defaultValues: {
      name: branch.name,
      place: defaultValue,
      latitude: branch.address.latitude.toString(),
      longitude: branch.address.longitude.toString(),
    },
  })

  // state
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<readonly Place[]>([])
  const value: Place | null = watch('place')

  const {
    setValue: setInputValue,
    suggestions: { loading: loadingOptions, data: places },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: userCompany.country },
      language: i18n.language,
    },
    debounce: 300,
  })

  useEffect(() => {
    let newOptions: readonly Place[] = []

    if (value) {
      newOptions = [value]
    }

    if (places) {
      newOptions = [
        ...newOptions,
        ...places.map(place => ({
          key: key(),
          description: place.description,
          structured_formatting: place.structured_formatting,
        })),
      ]
    }

    setOptions(newOptions)
  }, [places, value])

  const handleSuccess = (branch: Branch) => {
    setBranch(branch)

    const newValue = {
      key: defaultKey(),
      description: branch.address.description,
      structured_formatting: {
        main_text: branch.address.primary,
        secondary_text: branch.address.secondary,
        main_text_matched_substrings: [{ offset: 0, length: branch.address.primary.length }],
      },
    }

    setOptions([newValue, ...options])

    reset({
      name: branch.name,
      place: newValue,
      latitude: branch.address.latitude.toString(),
      longitude: branch.address.longitude.toString(),
    })

    dispatch(
      setSnackbar({
        message: t('Branch updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_BRANCH_NAME_ALREADY_EXIST) {
      setError('name', { message: EXCEPTION_BRANCH_NAME_ALREADY_EXIST })
      return
    }

    if (error.message === EXCEPTION_BRANCH_ADDRESS_ALREADY_EXIST) {
      setError('place', { message: EXCEPTION_BRANCH_ADDRESS_ALREADY_EXIST })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An error occurred while updating branch'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSelect = (place: Place | null) => {
    if (place) {
      // The selected place is equal than the current value.
      if (place.description === value?.description) {
        return
      }

      // The selected place is equal than the default value.
      if (place.description === branch.address.description) {
        // include the default value in the options to avoid warnings on the console.
        setOptions([defaultValue, ...options])
        setValue('place', defaultValue, { shouldDirty: true })
        setValue('latitude', branch.address.latitude.toString(), { shouldDirty: true })
        setValue('longitude', branch.address.longitude.toString(), { shouldDirty: true })
        return
      }

      // The selected place is not equal than the current value or the default value.
      getGeocode({ address: place.description }).then(results => {
        const { lat, lng } = getLatLng(results[0])
        // include the selected place in the options to avoid warnings on the console.
        setOptions([place, ...options])
        setValue('place', place, { shouldDirty: true })
        setValue('latitude', lat.toString(), { shouldDirty: true })
        setValue('longitude', lng.toString(), { shouldDirty: true })
      })
    }
  }

  const handleInputChange = (newInputValue: string, reason: string) => {
    clearErrors('place')

    if (newInputValue === '' || reason === 'clear') {
      clearSuggestions()
      setValue('place', null, { shouldDirty: true })
    }

    setInputValue(newInputValue)
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    setLoading(true)
    auth?.getIdToken().then(token => {
      branchApi
        .update(token, branch.id, {
          name: form.name,
          address: {
            description: form.place!.description,
            primary: form.place!.structured_formatting.main_text,
            secondary: form.place!.structured_formatting.secondary_text,
            latitude: Number(form.latitude),
            longitude: Number(form.longitude),
          },
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SubCard title={t('Details')}>
      <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'This field is required.',
                minLength: { value: 2, message: 'Name is too short.' },
                maxLength: { value: 50, message: 'Name is too long.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth>
                  <TextField
                    id='name'
                    label={t('Name')}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? t(error.message as string) : null}
                    slotProps={{
                      input: {
                        autoComplete: 'off',
                      },
                    }}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='place'
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field: { value }, fieldState: { error } }) => (
                <Autocomplete
                  id='locations'
                  options={options}
                  value={value || null}
                  loading={loadingOptions}
                  loadingText={t('Loading')}
                  noOptionsText={t('No locations')}
                  filterOptions={x => x}
                  fullWidth
                  autoComplete
                  filterSelectedOptions
                  onChange={(event, newValue: Place | null) => handleSelect(newValue)}
                  onInputChange={(event, newInputValue: string, reason: string) => handleInputChange(newInputValue, reason)}
                  getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                  getOptionKey={option => option.key}
                  isOptionEqualToValue={(option, value) => (value ? option.key === (value?.key || value) : false)}
                  renderInput={params => (
                    <TextField {...params} label={t('Address')} error={!!error} helperText={error ? t(error.message as string) : null} fullWidth />
                  )}
                  renderOption={(props, option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings || []

                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map((match: any) => [match.offset, match.offset + match.length]),
                    )

                    return (
                      <li {...props} key={option.key}>
                        <Grid container alignItems='center'>
                          <Grid sx={{ display: 'flex', width: 44 }}>
                            <LocationOnIcon sx={{ color: 'text.secondary' }} />
                          </Grid>
                          <Grid sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                            {parts.map((part, index) => (
                              <Box key={index} component='span' sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                                {part.text}
                              </Box>
                            ))}
                            <Typography variant='body2' color='text.secondary'>
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <LoadingButton type='submit' variant='contained' disabled={!formState.isDirty} loading={loading}>
              {t('Save changes')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </SubCard>
  )
}
