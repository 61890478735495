import { useContext, useEffect } from 'react'

// material-ui
import { Box, CssBaseline } from '@mui/material'

// third-party
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserContext from '../../context/user/UserContext'
import useDrawer from '../../hooks/useDrawer'
import AppBar from '../../layout/AppBar/AppBar'
import Sidebar from '../../layout/Sidebar/Sidebar'
import Main from '../../layout/Main/Main'
import Menu from './Menu/Menu'
import UserStatsProvider from '../../context/userstats/UserStatsProvider'

// ========================|| USER - LAYOUT ||======================== //

export default function UserLayout() {
  // hooks
  const { user } = useContext(UserContext)
  const { drawerOpened } = useDrawer()
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(user.locale)
  }, [])

  return (
    <UserStatsProvider>
      <Box display='flex'>
        <CssBaseline />
        {/* header */}
        <AppBar />

        {/* drawer */}
        <Sidebar>
          <Menu />
        </Sidebar>

        {/* main content */}
        <Main open={drawerOpened}>
          <Outlet />
        </Main>
      </Box>
    </UserStatsProvider>
  )
}
