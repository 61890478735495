import { Fragment, useContext } from 'react'

// material-ui
import { CardContent, Divider, Grid2 as Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import CompaniesContext from '../../../../context/company/CompaniesContext'
import CompanyListItem from '../CompanyListItem/CompanyListItem'
import ListPagination from '../../../../ui-components/extended/pagination/ListPagination'
import { Company } from '../../../../types/Company'

// ========================|| COMPANY - LIST ||======================== //

export default function CompanyList() {
  // hooks
  const { t } = useTranslation()
  const { loading, companies, params, setParams } = useContext(CompaniesContext)

  if (loading) {
    return null
  }

  if (companies.page.totalElements === 0) {
    return (
      <CardContent sx={{ padding: 3 }}>
        <Typography>{t('No companies found.')}</Typography>
      </CardContent>
    )
  }

  return (
    <CardContent sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        {companies.content.map((company: Company) => (
          <Fragment key={company.id}>
            <Grid size={12}>
              <CompanyListItem company={company} />
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Fragment>
        ))}
        <Grid display='flex' justifyContent='center' size={12}>
          <ListPagination count={companies.page.totalPages} pageable={params} setPageable={setParams} />
        </Grid>
      </Grid>
    </CardContent>
  )
}
