// material-ui
import { Autocomplete, Checkbox, FormControl, Grid2 as Grid, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useBranches from '../../../../hooks/branch/useBranches'
import useServices from '../../../../hooks/service/useServices'
import { AssistantCreateForm } from '../../../../types/Assistant'

// ========================|| ASSISTANT - FORM ||======================== //

export default function AssistantForm() {
  // hooks
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { control } = useFormContext<AssistantCreateForm>()
  const { loading: loadingBranches, branches } = useBranches(companyId)
  const { loading: loadingServices, services } = useServices(companyId)

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid size={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Name is too short.' },
            maxLength: { value: 100, message: 'Name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                id='name'
                label={t('Name')}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='branch'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <Autocomplete
                id='branch'
                options={branches}
                value={value || null}
                loading={loadingBranches}
                disableClearable
                onChange={(e, branch) => onChange(branch)}
                isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                getOptionLabel={branch => branch.name}
                renderInput={params => (
                  <TextField {...params} label={t('Branch')} error={!!error} helperText={error ? t(error.message as string) : null} />
                )}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='services'
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth>
              <Autocomplete
                options={services}
                value={value || null}
                loading={loadingServices}
                multiple
                limitTags={5}
                disableCloseOnSelect
                onChange={(e, service) => onChange(service)}
                isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                getOptionLabel={service => service.name}
                renderOption={(props, service, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      id={service.name}
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {service.name}
                  </li>
                )}
                renderInput={params => <TextField {...params} label={t('Services')} />}
              />
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  )
}
