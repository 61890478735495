import * as React from 'react'

// material-ui
import { Autocomplete, AutocompleteProps, Box, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import useServices from '../../../../hooks/service/useServices'
import { ServiceDetails } from '../../../../types/Service'

// ========================|| UI COMPONENTS - AUTOCOMPLETE - SERVICE ||======================== //

interface Props extends Omit<AutocompleteProps<ServiceDetails, false, false, false>, 'options' | 'loading' | 'getOptionKey' | 'isOptionEqualToValue'> {
  companyId: string
}

export default function ServiceAutocomplete({ companyId, ...props }: Props) {
  const { t } = useTranslation()
  const { loading, services } = useServices(companyId)

  return (
    <Autocomplete
      {...props}
      fullWidth
      options={services}
      loading={loading}
      getOptionKey={option => option.id}
      isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
      noOptionsText={t('No options')}
      getOptionLabel={service => service.name}
      renderOption={(props, service: ServiceDetails) => (
        <Box component='li' {...props}>
          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Typography>{service.name}</Typography>
            <Typography variant='caption'>
              {service.duration} <Typography component='span'>{t('minutes')}</Typography>
            </Typography>
          </Box>
        </Box>
      )}
    />
  )
}
