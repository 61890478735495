// material-ui
import { IconButton, Tooltip } from '@mui/material'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// project imports
import { UserAssistant } from '../../../../types/UserAssistant'

// ========================|| USER ASSISTANT - SEE BUTTON ||======================== //

interface Props {
  userAssistant: UserAssistant
}

export default function UserAssistantSeeButton({ userAssistant }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/assistants/${userAssistant.id}/bookings`)
  }

  return (
    <Tooltip title={t('See assistant')}>
      <IconButton aria-label='See assistant' color='primary' size='large' onClick={handleNavigate}>
        <VisibilityTwoToneIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}
