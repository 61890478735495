import { useContext } from 'react'

// material-ui
import { Grid2 as Grid } from '@mui/material'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import BookingSearchBar from '../BookingSearchBar/BookingSearchBar'
import BookingFilterButton from '../BookingFilterButton/BookingFilterButton'
import BookingFilterStatusTag from '../BookingFilterStatusTag/BookingFilterStatusTag'
import BookingFilterAssistantTag from '../BookingFilterAssistantTag/BookingFilterAssistantTag'
import BookingFilterStartDateTag from '../BookingFilterStartDateTag/BookingFilterStartDateTag'
import BookingFilterEndDateTag from '../BookingFilterEndDateTag/BookingFilterEndDateTag'
import BookingCreateButton from '../BookingCreateButton/BookingCreateButton'
import { Layout } from '../../../../types/Layout'

// ========================|| BOOKING - CARD HEADER ||======================== //

export default function BookingCardHeader() {
  const { layout, params } = useContext(BookingsContext)

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Grid container spacing={2} alignItems='center'>
          <Grid size='grow'>
            <BookingSearchBar />
          </Grid>
          <Grid container spacing={1} display='flex' alignItems='center'>
            <Grid>
              <BookingFilterButton />
            </Grid>
            {layout === Layout.COMPANY && (
              <Grid>
                <BookingCreateButton />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {params.status.length > 0 && (
        <Grid>
          <BookingFilterStatusTag />
        </Grid>
      )}
      {params.startDate && (
        <Grid>
          <BookingFilterStartDateTag />
        </Grid>
      )}
      {params.endDate && (
        <Grid>
          <BookingFilterEndDateTag />
        </Grid>
      )}
      {layout === Layout.COMPANY && params.assistants.length > 0 && (
        <Grid>
          <BookingFilterAssistantTag />
        </Grid>
      )}
    </Grid>
  )
}
