import { useContext, useEffect, useState } from 'react'

// material-ui
import { Autocomplete, Box, FormControl, Grid2 as Grid, TextField, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'

// third-party
import UserCompanyContext from '../../../../context/usercompany/UserCompanyContext'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import parse from 'autosuggest-highlight/parse'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import { Place } from '../../../../types/Place'
import { key } from '../../../../utils/keyGenerator'

// ========================|| BRANCH - FORM ||======================== //

export default function BranchForm() {
  // hooks
  const { i18n, t } = useTranslation()
  const { userCompany } = useContext(UserCompanyContext)
  const { control, watch, setValue, clearErrors } = useFormContext()
  const [options, setOptions] = useState<readonly Place[]>([])
  const value: Place = watch('place')

  const {
    setValue: setInputValue,
    suggestions: { loading, data: places },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: userCompany.country },
      language: i18n.language,
    },
    debounce: 300,
  })

  useEffect(() => {
    let newOptions: readonly Place[] = []

    if (value) {
      newOptions = [value]
    }

    if (places) {
      newOptions = [
        ...newOptions,
        ...places.map(place => ({
          key: key(),
          description: place.description,
          structured_formatting: place.structured_formatting,
        })),
      ]
    }

    setOptions(newOptions)
  }, [places])

  const handleSelect = (place: Place | null) => {
    if (place) {

      // The selected place is equal than the current value.
      if (place.description === value?.description) {
        return
      }

      getGeocode({ address: place.description }).then(results => {
        const { lat, lng } = getLatLng(results[0])
        setValue('place', place, { shouldDirty: true })
        setValue('latitude', lat.toString())
        setValue('longitude', lng.toString())
      })
    }
  }

  const handleInputChange = (newInputValue: string, reason: string) => {
    clearErrors('place')

    if (newInputValue === '' || reason === 'clear') {
      clearSuggestions()
      setValue('place', null)
    }

    setInputValue(newInputValue)
  }

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid size={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Name is too short.' },
            maxLength: { value: 50, message: 'Name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                id='name'
                label={t('Name')}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='place'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { value }, fieldState: { error } }) => (
            <Autocomplete
              id='locations'
              options={options}
              value={value || null}
              loading={loading}
              loadingText={`${t('Loading')}...`}
              noOptionsText={t('No locations')}
              filterOptions={x => x}
              fullWidth
              autoComplete
              filterSelectedOptions
              onChange={(event, newValue: Place | null) => handleSelect(newValue)}
              onInputChange={(event, newInputValue: string, reason: string) => handleInputChange(newInputValue, reason)}
              getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
              getOptionKey={option => option.key}
              isOptionEqualToValue={(option, value) => (value ? option.key === (value?.key || value) : false)}
              renderInput={params => (
                <TextField {...params} label={t('Address')} error={!!error} helperText={error ? t(error.message as string) : null} fullWidth />
              )}
              renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings || []

                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match: any) => [match.offset, match.offset + match.length]),
                )

                return (
                  <li {...props} key={option.key}>
                    <Grid container alignItems='center'>
                      <Grid sx={{ display: 'flex', width: 44 }}>
                        <LocationOnIcon sx={{ color: 'text.secondary' }} />
                      </Grid>
                      <Grid sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        {parts.map((part, index) => (
                          <Box key={index} component='span' sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                            {part.text}
                          </Box>
                        ))}
                        <Typography variant='body2' color='text.secondary'>
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                )
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
