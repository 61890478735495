// material-ui
import { Divider, Grid2 as Grid } from '@mui/material'

// project imports
import useBreadcrumbs from '../../../../hooks/company/useBreadcrumbs'
import useTooltip from '../../../../hooks/company/useTooltip'
import MainCard from '../../../../ui-components/extended/card/MainCard'
import MainContent from '../../../../ui-components/extended/table/MainContent'
import ClientTable from '../ClientTable/ClientTable'
import ClientSearchBar from '../ClientSearchBar/ClientSearchBar'
import ClientCreateButton from '../ClientCreateButton/ClientCreateButton'
import Breadcrumbs from '../../../../ui-components/extended/breadcrumbs/Breadcrumbs'

// ========================|| CLIENT - CARD ||======================== //

export default function ClientCard() {
  const items = useBreadcrumbs()
  const tooltip = useTooltip()

  return (
    <MainCard title={<Breadcrumbs items={items} tooltip={tooltip} />} content={false} border={false}>
      <MainContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid size='grow'>
            <ClientSearchBar />
          </Grid>
          <Grid>
            <ClientCreateButton />
          </Grid>
        </Grid>
      </MainContent>
      <Divider />
      <ClientTable />
    </MainCard>
  )
}